import React from "react";
import { Button, ListGroupItem } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImgBox from "../../ui/ImgBox";
import camelCaseToText from "../../../utils/camelCaseToText";
import { ACS, DEC } from "../../../constants";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";

const ListGroupHeaderCol = ({
  width,
  id,
  label = id ? camelCaseToText(id) : null,
  orderOptions = [],
  orderBy,
  setOrderBy,
  direction,
  setDirection,
  className = "",
  children,
  ...rest
}) => {
  const isSortable = id && orderOptions.indexOf(id) > -1;
  const isActive = orderBy === id;
  const style = {
    flexBasis: width,
    paddingTop: "var(--bs-list-group-item-padding-y)",
    paddingBottom: "var(--bs-list-group-item-padding-y)",
    textWrap: "nowrap",
  };

  const handleClick = () =>
    isActive
      ? setDirection((curr) => (curr === ACS ? DEC : ACS))
      : setOrderBy(id);

  return (
    <div
      style={style}
      className={`list-group-header-col${className ? " " + className : ""}`}
      {...rest}
    >
      {label}
      {isSortable ? (
        <>
          <Button
            variant="link"
            style={{
              ...style,
              textAlign: "unset",
              padding: 0,
              textDecoration: "unset",
              fontWeight: "unset",
              textWrap: "nowrap",
            }}
            className={(isActive ? "" : "link-secondary") + " align-baseline"}
            onClick={handleClick}
          >
            <BsArrowUp title={ACS} />
          </Button>

          <Button
            variant="link"
            style={{
              ...style,
              textAlign: "unset",
              padding: 0,
              textDecoration: "unset",
              fontWeight: "unset",
              textWrap: "nowrap",
            }}
            className={(isActive ? "" : "link-secondary") + " align-baseline"}
            onClick={handleClick}
          >
            <BsArrowDown title={DEC} />
            {children}
          </Button>
        </>
      ) : null}
      {children}
    </div>
  );
};

export const CustomerListHeader = ({ ...sortProps }) => {
  return (
    <ListGroupItem
      className={`d-flex gap-3 align-items-center sticky-top fw-semibold py-0 bg-gray-300 list-layout-header`}
    >
      <ListGroupHeaderCol width={26} className="flex-shrink-0" {...sortProps} />
      <ListGroupHeaderCol id="name" width={300} {...sortProps} />
      <ListGroupHeaderCol label="City" width={115} {...sortProps} />
      <ListGroupHeaderCol id="code" width={80} {...sortProps} />
      <ListGroupHeaderCol id="id" label="ID" width={60} {...sortProps} />
    </ListGroupItem>
  );
};
const CustomerListItem = ({ item, to, thumbIcon, imgId, isSelected }) => {
  return (
    <ListGroupItem
      as={Link}
      to={to}
      action
      active={isSelected}
      className={`d-flex gap-3 align-items-center`}
    >
      <ImgBox
        imgId={imgId}
        width={32}
        icon={thumbIcon}
        style={{
          flexBasis: 32,
        }}
        resizeMode="contain"
        imgBg="white"
        padding={2}
        className="flex-shrink-0 flex-grow-0 img-box-aspect-ratio-65 ms-n1"
      />

      <div className="text-truncate text-overflow" style={{ flexBasis: 300 }}>
        {item?.name}
      </div>
      <div
        className="text-truncate text-overflow fs-75 opacity-75"
        style={{ flexBasis: 115 }}
      >
        {item?.city}
      </div>
      <div
        className="text-truncate text-overflow font-monospace opacity-75"
        style={{ flexBasis: 80 }}
      >
        {item?.code}
      </div>

      <div className="font-monospace opacity-75" style={{ flexBasis: 60 }}>
        {item?.id}
      </div>
    </ListGroupItem>
  );
};

export default CustomerListItem;
