import React from "react";
import { Button } from "react-bootstrap";
import ExampleWrap from "./ExampleWrap";

const Buttons = ({ colors = [] }) => {
  const variants = [...colors, "link"];
  return (
    <>
      <h4>Variants</h4>
      <div>
        {variants.map((v) => (
          <Button variant={v} className="me-1 mb-1 min-w-8" key={`button${v}`}>
            {v}
          </Button>
        ))}
      </div>

      <div>
        {colors.map((c) => (
          <Button
            variant={`outline-${c}`}
            className="me-1 mb-1 min-w-8"
            key={`outlinebutton${c}`}
          >
            outline-{c}
          </Button>
        ))}
      </div>
      <h4>Sizes</h4>
      <Button size="sm" className="me-1 mb-1">
        size=sm
      </Button>
      <Button className="me-1 mb-1">default</Button>
      <Button size="lg" className="me-1 mb-1">
        size=lg
      </Button>

      <h4>Widths</h4>
      <Button className="me-1 mb-1 min-w-5">.min-w-5</Button>
      <Button className="me-1 mb-1 min-w-8">.min-w-8</Button>
      <Button className="me-1 mb-1 min-w-10">.min-w-10</Button>
    </>
  );
};

const ExampleButtons = ({ id, title }) => {
  return <ExampleWrap component={Buttons} id="buttons" title={title} />;
};

export default ExampleButtons;
