import { Card, Form, Table } from "react-bootstrap";

const VariantUsedConsumerParameters = ({
  usedConsumerParameters,
  consumerParameters,
  setConsumerParameters,
  editable,
}) => {
  const handleChange = (consumerparameter) => (e) => {
    const newValue = e.target.value;
    setConsumerParameters((prevConsumerParameters) => ({
      ...prevConsumerParameters,
      [consumerparameter]: newValue,
    }));
  };

  const getValue = (consumerparameter) => {
    return consumerParameters[consumerparameter] || "";
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title style={{ fontSize: "18px" }}>
          Used Consumer Parameters
        </Card.Title>
      </Card.Header>
      <Card.Body style={{ padding: 0 }}>
        <Table responsive hover striped>
          <thead>
            <tr>
              <th>{"Key"}</th>
              <th>{"Value"}</th>
            </tr>
          </thead>
          <tbody>
            {usedConsumerParameters?.map((consumerparameter, index) => (
              <tr key={index}>
                <td>{consumerparameter}</td>
                <td style={{ width: "50%" }}>
                  <Form.Control
                    disabled={!editable}
                    value={getValue(consumerparameter)}
                    placeholder={`${consumerparameter}...`}
                    onChange={handleChange(consumerparameter)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default VariantUsedConsumerParameters;
