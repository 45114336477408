import React from "react";
import ExportDropdownBtn, { ExportOption } from "../../ui/ExportDropdownBtn";
import { DropdownDivider, DropdownHeader } from "react-bootstrap";
import CountBadge from "../../custom/CountBadge";

const CustomerExportDropdown = ({ customer, mp, suppliers, persons }) => {
  return (
    <ExportDropdownBtn>
      <DropdownHeader>Export .csv</DropdownHeader>
      <ExportOption
        format="csv"
        exportData={customer?.addresses}
        title="Customer addresses"
        fileName={`${customer?.id} addresses`}
      />
      <ExportOption
        format="csv"
        exportData={customer?.contactDetails}
        title="Customer contact details"
        fileName={`${customer?.id} contact details`}
      />
      <DropdownDivider />
      <DropdownHeader>Copy JSON</DropdownHeader>
      <ExportOption
        format="json"
        exportData={customer}
        title="General, addresses, contact details"
        extraText={false}
        successHeading="General, addresses, contact details"
      />
      <ExportOption
        format="json"
        exportData={mp}
        title="Marketing profile"
        extraText={false}
        successHeading="Marketing profile"
      />
      <ExportOption
        format="json"
        exportData={suppliers}
        title={
          <>
            Suppliers
            <CountBadge count={suppliers?.length} />
          </>
        }
        extraText={false}
        successHeading="Suppliers"
        disabled={!suppliers?.length}
      />
      <ExportOption
        format="json"
        exportData={persons}
        title={
          <>
            Contact persons
            <CountBadge count={persons?.length} />
          </>
        }
        extraText={false}
        successHeading="Contact persons"
      />
    </ExportDropdownBtn>
  );
};

export default CustomerExportDropdown;
