import React, { createContext, useState } from "react";

const LayoutContext = createContext();

export const LayoutProvider = ({ children }) => {
  const [hideCollapsed, setHideCollapsed] = useState(false);

  return (
    <LayoutContext.Provider value={{ hideCollapsed, setHideCollapsed }}>
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutContext;
