import { useEffect, useState } from "react";
import { useAuthContext } from "../../../../context/AuthContext";
import useFetch from "../../../../utils/useFetch";
import { config } from "../../../../config";
import { Button, Modal, Table } from "react-bootstrap";
import Moment from "react-moment";
import createDetailedErrorMessage from "../../../../utils/detailMessageError";
import fetcher from "../../../../utils/fetcher";

const LinkToOrder = ({ variant, customerId, programId, treatmentId }) => {
  const { setToasts, client } = useAuthContext();
  const [error, setError] = useState(null);
  const [showOpen, setShowOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [orderViewModels, setOrderViewModels] = useState([]);
  const [ordersWithEtags, setOrdersWithEtags] = useState([]);

  const { data: orders } = useFetch({
    //first fetch the orders based on the right program and customer
    url: `${config.api.order}/orders?customerId=${customerId}&programId=${programId}`,
  });

  const { data: products } = useFetch({
    url: `${config.api.order}/products?programId=${programId}&treatmentId=${treatmentId}`,
  });

  //this useEffect function again fetches orders based on orderId and then assigns the etag to each order
  useEffect(() => {
    if (!orders || orders.length === 0) return;

    const fetchOrdersWithEtags = async () => {
      try {
        const orderPromises = orders.map((order) =>
          fetcher({
            url: `${config.api.order}/orders/${order.id}`,
            method: "GET",
            customerId: customerId,
            clientId: client?.id,
          }).then((res) => {
            return {
              order: res.data,
              etag: res.headers.etag,
            };
          })
        );

        const ordersWithEtagsArray = await Promise.all(orderPromises);
        setOrdersWithEtags(ordersWithEtagsArray);
      } catch (error) {
        setError(error);
      }
    };

    fetchOrdersWithEtags();
  }, [orders, customerId, client]);

  //this useEffect creates a list of models that contain all data of orders needed for display or to be submitted to endpoint
  useEffect(() => {
    //function processOrders which goes through all orders and assigns the coherent product to the order
    const processOrders = () => {
      const models = [];

      ordersWithEtags.forEach(({ order, etag }) => {
        //for each order we go through all productItems and filter out the right one
        const productItems = [
          ...(order.productItems || []),
          ...(order.packageItems || []).flatMap(
            (packageItem) => packageItem.products || []
          ),
        ];

        const product = determineProductName(productItems); //we filter out the right productItem

        if (!product) {
          return;
        }

        models.push({
          //if done correctly we assign the productName to this object
          id: order.id,
          programId: order.program.id,
          programName: order.program.name,
          participationDate: order.participationDate,
          orderDate: order.orderDate,
          customerCode: order.customer.code,
          customerName: order.customer.name,
          customerId: order.customer.id,
          modified: order.modified,
          productName: product.name,
          productId: product.id,
          productCode: product.code,
          etag: etag,
        });
      });
      setOrderViewModels(models); //finally set models to orderViewModels
    };

    //function determineProductName which determines the right productName given the productItems
    const determineProductName = (productItems) => {
      //This function filters out the right ProductItem and returns the correct productName
      const product = products[0]; //We always need to take the first product

      const productSet = new Set(); //make it a unique set -> same productItem can occur multiple times
      productItems.forEach((item) => {
        if (product.id === item.productId) {
          if (item.isCustom || product.treatmentId === treatmentId) {
            productSet.add(product);
          }
        }
      });

      const newProduct = Array.from(productSet);

      return newProduct[0];
    };

    if (ordersWithEtags?.length > 0) {
      processOrders(); //we execute the two above functions
    }
  }, [ordersWithEtags, products, treatmentId]);

  const handleLinkToOrder = () => {
    const matchingOrder = orderViewModels.find(
      (order) => order.id === selectedOrder.id
    );

    const payload = {
      orderItemId: matchingOrder.id,
      productId: matchingOrder.productId,
      productName: matchingOrder.productName,
      productCode: matchingOrder.productCode,
    };

    fetcher({
      url: `${config.api.order}/orders/${matchingOrder.id}/variants?variantId=${variant.id}`,
      method: "POST",
      payload: payload,
      customerId: variant.customer.id,
      clientId: client?.id,
      headers: {
        "If-Match": matchingOrder.etag,
      },
    })
      .then((res) => {
        setError("Variant successfully linked to order");
      })
      .catch((err) => {
        setError(err);
      });
  };

  useEffect(() => {
    if (error === "Variant successfully linked to order") {
      setToasts(() => [
        {
          id: Date.now(),
          variant: "success",
          heading: "Succes!",
          delay: 50000,
          text: "Variant is successfully linked to order ",
        },
      ]);
    }
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Failed to link variant to order",
          delay: 50000,
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  return (
    <div className="w-100">
      <Modal
        size="xl"
        show={showOpen}
        onHide={() => {
          setShowOpen(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Link to Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ maxHeight: "450px", overflowY: "auto" }}>
            {Object.keys(orderViewModels).length > 0 ? (
              <Table striped hover>
                <thead>
                  <tr>
                    <th>id</th>
                    <th>Participation date</th>
                    <th>Order date</th>
                    <th>Customer code</th>
                    <th>Customer name</th>
                    <th>Program name</th>
                    <th>Modified</th>
                    <th>Product name</th>
                  </tr>
                </thead>
                <tbody>
                  {orderViewModels?.map((item) => (
                    <tr key={item.id} onClick={() => setSelectedOrder(item)}>
                      <td
                        style={
                          selectedOrder.id === item.id
                            ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                            : {}
                        }
                      >
                        {item.id}
                      </td>
                      <td
                        style={
                          selectedOrder.id === item.id
                            ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                            : {}
                        }
                      >
                        {item.participationDate ? (
                          <Moment format="DD MMM YYYY HH:mm">
                            {item.participationDate}
                          </Moment>
                        ) : null}
                      </td>
                      <td
                        style={
                          selectedOrder.id === item.id
                            ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                            : {}
                        }
                      >
                        {item.orderDate ? (
                          <Moment format="DD MMM YYYY HH:mm">
                            {item.orderDate}
                          </Moment>
                        ) : null}
                      </td>
                      <td
                        style={
                          selectedOrder.id === item.id
                            ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                            : {}
                        }
                      >
                        {item.customerCode}
                      </td>
                      <td
                        style={
                          selectedOrder.id === item.id
                            ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                            : {}
                        }
                      >
                        {item.customerName}
                      </td>
                      <td
                        style={
                          selectedOrder.id === item.id
                            ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                            : {}
                        }
                      >
                        {item.programName}
                      </td>
                      <td
                        style={
                          selectedOrder.id === item.id
                            ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                            : {}
                        }
                      >
                        <Moment format="DD MMM YYYY HH:mm">
                          {item.modified}
                        </Moment>
                      </td>
                      <td
                        style={
                          selectedOrder.id === item.id
                            ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                            : {}
                        }
                      >
                        {item.productName}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div className="w-100 d-flex alignt-items-center justify-content-center">
                {"No order can be linked to this variant"}
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowOpen(false);
              setSelectedOrder({});
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleLinkToOrder();
              setSelectedOrder({});
              setShowOpen(false);
            }}
            disabled={Object.keys(selectedOrder).length === 0}
            variant="success"
          >
            Link
          </Button>
        </Modal.Footer>
      </Modal>
      <Button variant="secondary" size="sm" onClick={() => setShowOpen(true)}>
        Link to Order
      </Button>
    </div>
  );
};
export default LinkToOrder;
