import { useParams } from "react-router-dom";
import { useAuthContext } from "../../../../context/AuthContext";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import fetcher from "../../../../utils/fetcher";
import { config } from "../../../../config";
import createDetailedErrorMessage from "../../../../utils/detailMessageError";
import { Button, Card, Col, Form, ListGroupItem, Modal } from "react-bootstrap";
import {
  BsCalendarCheckFill,
  BsCheck2,
  BsPersonCircle,
  BsX,
} from "react-icons/bs";
import Moment from "react-moment";

const VariantCustomerApproval = ({
  customerApproval,
  refreshVariants,
  refreshHistory,
}) => {
  const { variantId, customerId } = useParams();
  const { client, setToasts } = useAuthContext();

  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);

  const { register, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      approved: customerApproval?.approved,
      description: customerApproval?.description,
    },
  });

  const handleOnCancel = () => {
    setShowModal(false);
    reset({
      approved: customerApproval?.approved,
      description: customerApproval?.description,
    });
  };

  const onSubmit = (data) => {
    fetcher({
      url: `${config.api.phoenix}/variants/${variantId}/customerApproval`,
      method: "PUT",
      payload: data,
      customerId: customerId,
      clientId: client?.id,
    })
      .then(() => {
        refreshVariants();
        refreshHistory();
        reset();
      })
      .catch((err) => {
        setError(err);
        reset();
      });

    setShowModal(false);
  };

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Failed to cancel customer approval",
          delay: 50000,
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  return (
    <Card style={{ width: "80%" }} className="h-100 fade-in-grow-up">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card.Header className="d-flex justify-content-center">
          <Card.Title>Customer Approval</Card.Title>
        </Card.Header>

        <Card.Body className="w-100 d-flex">
          <Col className="w-100">
            <div className="d-flex w-100">
              <Col className="mb-3 d-flex">
                <ListGroupItem className="hover-parent match-floating-input">
                  <Col className="inner flex-grow-1 flex-shrink-0">
                    {customerApproval.timestamp ? (
                      <>
                        <BsCalendarCheckFill className="text-success-tint-25 me-1" />
                        {customerApproval?.timestamp !== null ? (
                          <Moment format="DD MMM YYYY HH:mm">
                            {customerApproval?.timestamp}
                          </Moment>
                        ) : null}
                      </>
                    ) : null}
                  </Col>
                </ListGroupItem>
              </Col>
              <Col className="mb-3 d-flex">
                <ListGroupItem className="hover-parent match-floating-input">
                  <Col className="inner flex-grow-1 flex-shrink-0">
                    {customerApproval.userName ? (
                      <>
                        <BsPersonCircle /> {customerApproval?.userName}
                      </>
                    ) : null}
                  </Col>
                </ListGroupItem>
              </Col>
            </div>
            <div className="d-flex">
              <ListGroupItem className="hover-parent match-floating-input">
                <Col className="inner flex-grow-1 flex-shrink-0">
                  {customerApproval.description
                    ? customerApproval?.description
                    : null}
                </Col>
              </ListGroupItem>
            </div>
          </Col>
        </Card.Body>
        <Card.Footer>
          <div className="text-end">
            <Button
              disabled={customerApproval?.approved === false}
              variant="secondary"
              onClick={() => {
                setValue("description", "");
                setValue("approved", false);
                setShowModal(true);
              }}
            >
              {"Cancel Approve"}
            </Button>
          </div>
        </Card.Footer>
        <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header>
            <Modal.Title>Cancelling customer approval</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span>{"Are you sure you want to cancel customer approval?"}</span>
            <br />
            <br />
            <Form.Control
              placeholder="Provide reason (optional)"
              {...register("description")}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleOnCancel}>
              <BsX className="btn-icon me-1" /> Cancel
            </Button>
            <Button
              variant="success"
              type="submit"
              onClick={handleSubmit(onSubmit)}
            >
              <BsCheck2 className="btn-icon me-1" /> Save changes
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </Card>
  );
};

export default VariantCustomerApproval;
