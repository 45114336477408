import React from "react";
import { Button, ListGroupItem } from "react-bootstrap";
import { BsPencil } from "react-icons/bs";
import ContactTypeIcon from "./ContactTypeIcon";
import SpacedItem from "../../SpacedItem";
import { VALIDATIONPATTERNS } from "../../../constants";
import { Link } from "react-router-dom";

const ContactDetailsListItem = ({
  id,
  type,
  value,
  notes,
  className,
  multiline = false,
  to, // as Link
  onClick, // as Button
  ...rest
}) => {
  const linkProps = to ? { to } : {};
  const btnProps = onClick ? { onClick } : {};

  const {
    email: emailPattern,
    url: urlPattern,
    phone: phonePattern,
    numeric: numericPattern,
  } = VALIDATIONPATTERNS;
  const isEmail = emailPattern.test(value);
  const isUrl = urlPattern.test(value);
  const isPhone = phonePattern.test(value) || numericPattern.test(value);

  const isLink = isEmail || isUrl;

  return (
    <ListGroupItem
      action={to || onClick}
      className={`hover-parent d-flex justify-content-start align-items-center${
        className ? ` ${className}` : ""
      }`}
      {...linkProps}
      {...btnProps}
      {...rest}
    >
      <ContactTypeIcon
        type={type}
        className={`text-secondary-tint-10${
          multiline ? " ms-1 me-3 my-2 align-self-start" : " me-2 ms-n1"
        }`}
        title={type}
      />

      <div>
        {type ? (
          <span
            className={`small text-muted me-2${multiline ? " d-block" : ""}`}
          >
            {type}
          </span>
        ) : null}

        {isLink ? (
          <Link
            to={`${isEmail ? "mailto:" : ""}${value}`}
            className={`${multiline ? "d-block" : ""} small`}
          >
            {value}
          </Link>
        ) : (
          <span className={`${multiline ? "d-block" : ""}`}>{value}</span>
        )}

        <SpacedItem>
          <span
            className={`${multiline ? "d-block mt-1" : ""}${
              isPhone ? " font-monospace" : ""
            } small `}
          >
            {notes}
          </span>
        </SpacedItem>
      </div>

      {to ? (
        <Button
          as="span"
          variant="link"
          className={`hover-opacity ${
            multiline ? " align-self-end me-n1" : " my-n2 me-n3"
          }`}
          title="Edit"
        >
          <BsPencil />
        </Button>
      ) : null}
    </ListGroupItem>
  );
};

export default ContactDetailsListItem;
