import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Form, FormGroup } from "react-bootstrap";
import { config } from "../../../../config";
import { useParams } from "react-router-dom";
import fetcher from "../../../../utils/fetcher";
import { useAuthContext } from "../../../../context/AuthContext";
import createDetailedErrorMessage from "../../../../utils/detailMessageError";
import {
  BsBoxArrowUpRight,
  BsFullscreen,
  BsFullscreenExit,
  BsPencil,
  BsX,
} from "react-icons/bs";
import { useForm } from "react-hook-form";
import VariantDMProof from "./VariantDMProof";
import WithLoaderAndError from "../../../ui/WithLoaderAndError";
import VariantProofHistory from "./VariantProofHistory";
import VariantUsedConsumerParameters from "./VariantUsedConsumerParameters";

const VariantProofing = ({
  refreshProofHistory,
  proofHistory,
  usedConsumerParameters,
}) => {
  const { variantId, customerId } = useParams();
  const { client, user, setToasts } = useAuthContext();
  const [format, setFormat] = useState("pdf");
  const [action, setAction] = useState(true);
  const [editable, setEditable] = useState(false);
  const [loading, setLoading] = useState(true);

  const [proofs, setProofs] = useState([]);
  const [proofType, setProofType] = useState(null);
  const [error, setError] = useState(null);
  const [flipPosition, setFlipPosition] = useState(0);

  const [scale, setScale] = useState(1);
  const handleFlip = () => setFlipPosition((prev) => (prev < 3 ? prev + 1 : 0));
  const resetFlip = () => setFlipPosition(0);

  const [isFullscreen, setIsFullscreen] = useState(false);
  const containerRef = useRef(null);

  const [consumerParameters, setConsumerParameters] = useState({});

  const defaultValues = {
    highQuality: false,
    recipientEmail: null,
    parameters: null,
    useIndesignRendering: true,
    onlyOneConsumer: true,
    format: "pdf",
    ignoreProofHistory: false,
  };

  const { reset, register, watch, handleSubmit, setValue } = useForm({
    defaultValues,
  });

  useEffect(() => {
    setLoading(true);
    fetcher({
      url: `${config.api.phoenix}/variants/${variantId}/proofs`,
      method: "POST",
      payload: {
        format: "pdf",
        highQuallity: false,
        recipientEmail: null,
        parameters: null,
        useIndesignRendering: true,
        onlyOneConsumer: true,
        ignoreProofHistory: false,
      },
      customerId: customerId,
      clientId: client?.id,
    })
      .then((res) => {
        setLoading(false);
        setProofs(res.data.results);
        setProofType(res.data.type);
        setError(null);
      })
      .catch((err) => {
        setLoading(false);
        setProofs([]);
        setProofType(null);
        setError(err);
      });
  }, [variantId, client, customerId]);

  const createProof = (data) => {
    const payload = {
      highQuality: data.highQuality,
      recipientEmail: data.recipientEmail,
      useIndesignRendering: data.useIndesignRendering,
      onlyOneConsumer: data.onlyOneConsumer,
      format: data.format,
      ignoreProofHistory: data.ignoreProofHistory,
      parameters:
        Object.keys(consumerParameters).length > 0 ? consumerParameters : null,
    };

    fetcher({
      url: `${config.api.phoenix}/variants/${variantId}/proofs`,
      method: "POST",
      payload: payload,
      customerId: customerId,
      clientId: client?.id,
    })
      .then((res) => {
        setFormat(data.format);
        setProofs(res.data.results);
        setProofType(res.data.type);
        refreshProofHistory();
        setError(null);
        setEditable(false);
      })
      .catch((err) => {
        setFormat(data.format);
        setProofs([]);
        setProofType(null);
        setError(err);
        reset();
        setEditable(false);
      });
  };

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Failed render proof",
          delay: 50000,
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      if (containerRef.current.requestFullscreen) {
        containerRef.current.requestFullscreen();
      } else if (containerRef.current.webkitRequestFullscreen) {
        /* Safari */
        containerRef.current.webkitRequestFullscreen();
      } else if (containerRef.current.msRequestFullscreen) {
        /* IE11 */
        containerRef.current.msRequestFullscreen();
      }
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
      setIsFullscreen(false);
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(document.fullscreenElement === containerRef.current);
    };
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const handleOpenInTab = (url) => window.open(url);

  return (
    <div>
      <Form className="d-flex w-100 h-100" onSubmit={handleSubmit(createProof)}>
        <Card className="w-100 h-100">
          <Card.Header>
            <Card.Title>{"New Proof"}</Card.Title>
          </Card.Header>
          <div className="d-flex h-100 ">
            <Card.Body
              style={{
                paddingLeft: 0,
                paddingRight: 0,
              }}
              className="d-flex align-items-center"
            >
              <div className="w-100">
                <div className="w-100" style={{ padding: 20 }}>
                  {proofType === "dm" ? (
                    <>
                      <Form.Group className="d-flex justify-content-between">
                        <div className="d-flex mb-4">
                          <Form.Label>Format: </Form.Label>
                          <Form.Check
                            style={{ marginLeft: "10px" }}
                            label="PDF"
                            type="radio"
                            disabled={!editable}
                            checked={watch("format") === "pdf"}
                            onChange={() => setValue("format", "pdf")}
                          />
                          <Form.Check
                            style={{ marginLeft: "39px" }}
                            label="JPG"
                            type="radio"
                            disabled={!editable}
                            checked={watch("format") === "jpg"}
                            onChange={() => setValue("format", "jpg")}
                          />
                        </div>
                      </Form.Group>
                      <Form.Group className="d-flex mb-5">
                        <Form.Label>Quality:</Form.Label>
                        <div className="d-flex" style={{ marginLeft: "10px" }}>
                          <Form.Check
                            label="Default"
                            type="radio"
                            disabled={!editable}
                            checked={!watch("highQuality")}
                            onChange={() => setValue("highQuality", false)}
                          />
                          <Form.Check
                            style={{ marginLeft: "15px" }}
                            label="High"
                            type="radio"
                            disabled={!editable}
                            checked={watch("highQuality")}
                            onChange={() => setValue("highQuality", true)}
                          />
                        </div>
                      </Form.Group>
                      <Form.Check
                        className="mb-4"
                        label="Use Indesign Rendering"
                        disabled={!editable}
                        checked={watch("useIndesignRendering")}
                        onChange={() =>
                          setValue(
                            "useIndesignRendering",
                            !watch("useIndesignRendering")
                          )
                        }
                      />
                      <Form.Check
                        className="mb-4"
                        label="Only One Consumer"
                        disabled={!editable}
                        checked={watch("onlyOneConsumer")}
                        onChange={() =>
                          setValue("onlyOneConsumer", !watch("onlyOneConsumer"))
                        }
                      />
                    </>
                  ) : null}
                  <Form.Check
                    className="mb-4"
                    label="Skip Cache (forces creating a new proof)"
                    disabled={!editable}
                    checked={watch("ignoreProofHistory")}
                    onChange={() =>
                      setValue(
                        "ignoreProofHistory",
                        !watch("ignoreProofHistory")
                      )
                    }
                  />
                  <FormGroup className="mb-5 d-flex">
                    <Form.Check
                      className="w-100"
                      label="Send proof to email"
                      disabled={!editable}
                      checked={!action}
                      onChange={() => {
                        setAction(!action);
                        setValue("recipientEmail", user?.email);
                      }}
                    />
                    {action === false ? (
                      <Form.Control
                        {...register("recipientEmail")}
                        disabled={action || !editable}
                      />
                    ) : null}
                  </FormGroup>
                </div>

                {!!usedConsumerParameters &&
                usedConsumerParameters.length > 0 ? (
                  <div className="w-100 h-100 mb-5">
                    <VariantUsedConsumerParameters
                      usedConsumerParameters={usedConsumerParameters}
                      consumerParameters={consumerParameters}
                      setConsumerParameters={setConsumerParameters}
                      editable={editable}
                    />
                  </div>
                ) : null}

                <div
                  className="d-flex justify-content-between"
                  style={{ paddingLeft: 20, paddingRight: 20 }}
                >
                  <Button
                    disabled={!editable}
                    type="submit"
                    className="min-w-8"
                  >
                    Proof
                  </Button>
                  {editable ? (
                    <Button
                      variant="secondary"
                      onClick={() => setEditable(false)}
                      className="me-2 min-w-8"
                    >
                      <BsX className="btn-icon me-1" />
                      Cancel
                    </Button>
                  ) : (
                    <Button
                      variant="success"
                      onClick={() => setEditable(true)}
                      className="min-w-8"
                    >
                      <BsPencil className="btn-icon me-1" /> Edit
                    </Button>
                  )}
                </div>
              </div>
            </Card.Body>

            <div
              ref={containerRef}
              className={`h-100 ${isFullscreen ? "fullscreen" : ""}`}
              style={{
                minHeight: "550px",
                width: "45%",
                position: "relative",
              }}
            >
              <WithLoaderAndError isLoading={loading}>
                {proofs?.length > 0 ? (
                  proofType === "edm" ? (
                    !!proofs[0].urls &&
                    !!proofs[0].urls.length &&
                    !!proofs[0].documentId ? (
                      <div className="img-edm-wrap h-100">
                        {/* EDM proof */}
                        <iframe
                          src={proofs[0].urls[0]}
                          title={`id: ${proofs[0].documentId}`}
                          style={
                            isFullscreen
                              ? { height: "100%" }
                              : { height: "600px" }
                          }
                        />
                        <div
                          className="abs-buttons"
                          style={isFullscreen ? { right: 450, top: 40 } : {}}
                        >
                          <Button
                            onClick={toggleFullscreen}
                            variant="secondary"
                          >
                            {isFullscreen ? (
                              <BsFullscreenExit />
                            ) : (
                              <BsFullscreen />
                            )}
                          </Button>
                          <Button
                            variant="secondary"
                            onClick={() => handleOpenInTab(proofs[0].urls)}
                          >
                            <BsBoxArrowUpRight />
                          </Button>
                        </div>
                      </div>
                    ) : null
                  ) : (
                    <VariantDMProof
                      proofs={proofs}
                      format={format}
                      setScale={setScale}
                      flipPosition={flipPosition}
                      handleFlip={handleFlip}
                      scale={scale}
                      resetFlip={resetFlip}
                      handleOpenInTab={handleOpenInTab}
                    />
                  )
                ) : null}
              </WithLoaderAndError>
            </div>
          </div>
          <Card.Footer className="pt-2 p-0">
            <Card.Title>Proofing History</Card.Title>
            <VariantProofHistory proofHistory={proofHistory} />
          </Card.Footer>
        </Card>
      </Form>
    </div>
  );
};

export default VariantProofing;
