import React, { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import useFetch from "../../../utils/useFetch";
import { config } from "../../../config";
import {
  Badge,
  Button,
  Card,
  Col,
  ListGroup,
  Row,
  Stack,
} from "react-bootstrap";
import WithLoaderAndError from "../../ui/WithLoaderAndError";
import ImgBox from "../../ui/ImgBox";
import { FaBan, FaRegCirclePause, FaStore } from "react-icons/fa6";
import { BsIncognito, BsTagFill, BsXLg } from "react-icons/bs";
import { GoSidebarCollapse, GoSidebarExpand } from "react-icons/go";
import AddressListItem from "./AddressListItem";
import ContactDetailsListItem from "./ContactDetailsListItem";
import SupplierListItem from "./SupplierListItem";
import PersonsListItem from "./PersonsListItem";
import LayoutContext from "../../../context/LayoutContext";
import DOMPurify from "dompurify";
import CustomerExportDropdown from "./CustomerExportDropdown";
import CountBadge from "../../custom/CountBadge";
import moment from "moment";
import camelCaseToText from "../../../utils/camelCaseToText";

// "id": 101277,
// "code": "1233221",
// "mdbCustomerId": null,
// "parentId": null,

// "logoFileId": null,

// "language": "NL",

// "active": true,
// "isTestCustomer": true,
// "onHold": null,
//   "onHoldBy": null,
//   "onHoldDescription": "Test",

// "name": "Test Customer 10",
//   "marketingName": null,
//   "username": null,

// "internalLabels": [],
// "externalLabels": [],

// "description": "tertgtrtg",
// "openingTimes": null,

// "fulfilmentPartnerId": 1,
// "customerGroupDto": null,
// "accountManager": "f31c887c-f927-e211-be9e-0023aeff5648",
// "externalAccountManager": null,
// "salesManager": "5663e44e-02e0-e311-8eb1-0023aeff5648",
// "projectManager": "5663e44e-02e0-e311-8eb1-0023aeff5648",
// "projectManagerDto": null,
// "marketingConsultant": null,

// "addresses": [
//   {
//     "type": "shop",
//     "name": "Main address",
//     "street": "Milky Way",
//     "houseNumber": "1128",
//     "suffix": "K",
//     "postalCode": "4218",
//     "city": "Croctopus",
//     "province": "Nowhere",
//     "country": "Everland"
//   },
// ],

// "contactDetails": []

const CustomerViewInner = ({ customer, mp, suppliers = [], persons = [] }) => {
  return (
    <div>
      <Row>
        <Col xs="auto">
          <ImgBox
            imgId={mp?.images?.logo?.fileId}
            width={300}
            padding={10}
            className="fade-in delay img-box-aspect-ratio-65"
            icon={FaStore}
            imgBg="white"
            imgColor="rgba(var(--bs-px-dark-rgb), .2)"
            resizeMode="contain"
            style={{ width: 300 }}
          />
        </Col>
        <Col>
          <h1 className="mb-1">{customer?.name}</h1>
          {customer?.externalLabels?.length ||
          customer?.internalLabels?.length ? (
            <div className="my-1">
              {customer?.internalLabels.map(
                ({
                  id: labelId,
                  name: labelName,
                  description: labelDescription,
                }) => (
                  <Badge
                    bg="px-dark-tint-50"
                    className="me-2"
                    key={labelId}
                    title={
                      !!labelDescription && labelDescription !== labelName
                        ? labelDescription
                        : null
                    }
                  >
                    {labelName}
                    <BsIncognito title="Internal label" className="ms-1" />
                  </Badge>
                )
              )}
              {customer?.externalLabels.map(
                ({
                  id: labelId,
                  name: labelName,
                  description: labelDescription,
                }) => (
                  <Badge
                    bg="px-dark-tint-50"
                    className="me-2"
                    key={labelId}
                    title={
                      !!labelDescription && labelDescription !== labelName
                        ? labelDescription
                        : null
                    }
                  >
                    {labelName}
                    <BsTagFill
                      className="opacity-75 ms-1"
                      title="External label"
                    />
                  </Badge>
                )
              )}
            </div>
          ) : null}
          <Row>
            <Col xs="auto">
              <div className="my-1">
                <div>
                  <span className="small text-muted me-2">ID</span>
                  <span className="font-monospace small">{customer?.id}</span>
                </div>
                <div>
                  <span className="small text-muted me-2">Code</span>
                  <span className="font-monospace small">{customer?.code}</span>
                </div>
                <div>
                  <span className="small text-muted me-2">mdbCustomerId</span>
                  <span className="font-monospace small">
                    {customer?.mdbCustomerId}
                  </span>
                </div>
                <div>
                  <span className="small text-muted me-2">parentId</span>
                  <span className="font-monospace small">
                    {customer?.parentId}
                  </span>
                </div>
              </div>

              <div className="my-1">
                {customer?.marketingName ? (
                  <div className="my-1">
                    <span className="small text-muted">Marketing name</span>
                    <div className="lh-sm">{customer?.marketingName}</div>
                  </div>
                ) : null}
                {customer?.username ? (
                  <div className="my-1">
                    <span className="small text-muted">Username</span>
                    <div>{customer?.username}</div>
                  </div>
                ) : null}
              </div>
            </Col>

            {customer?.description ? (
              <Col>
                <div className="my-1">
                  <span className="small text-muted">Description</span>
                  <div
                    className="small"
                    style={{ whiteSpace: "pre" }}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(customer?.description, {
                        USE_PROFILES: { html: true },
                      }),
                    }}
                  />
                </div>
              </Col>
            ) : null}
            <Col xs="auto">
              {customer?.openingTimes ? (
                <div className="my-1">
                  <span className="small text-muted">Opening times</span>
                  <div
                    className="small"
                    style={{ whiteSpace: "pre" }}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(customer?.openingTimes, {
                        USE_PROFILES: { html: true },
                      }),
                    }}
                  />
                </div>
              ) : null}
            </Col>
          </Row>
        </Col>
      </Row>

      {/* <Stack direction="vertical" gap={3}>
        <LabeledItem
          label="marketingName"
          name={marketingName}
          fixLabel
          matchInputPadding={false}
        />
        <LabeledItem
          label="username"
          name={username}
          fixLabel
          matchInputPadding={false}
        />

        <LabeledItem
          label="description"
          name={description}
          fixLabel
          matchInputPadding={false}
        />
        <LabeledItem
          label="openingTimes"
          name={openingTimes}
          fixLabel
          matchInputPadding={false}
        />
      </Stack> */}

      <Row className="g-4 mt-3">
        <Col>
          <h2>
            Addresses
            <CountBadge count={customer?.addresses?.length} />
          </h2>

          <ListGroup
            variant="flush"
            style={{
              // "--bs-list-group-item-padding-y": ".25rem",
              "--bs-list-group-border-width": 0,
            }}
          >
            {customer?.addresses.map((item, i) => (
              <AddressListItem key={item?.name + i} item={item} />
            ))}
          </ListGroup>
        </Col>
        <Col>
          <h2>
            Contact details
            <CountBadge count={customer?.contactDetails?.length} />
          </h2>

          <ListGroup
            variant="flush"
            style={{
              // "--bs-list-group-item-padding-y": ".25rem",
              "--bs-list-group-border-width": 0,
            }}
          >
            {customer?.contactDetails.map(({ id, type, value, notes }) => (
              <ContactDetailsListItem
                key={id}
                type={type}
                value={value}
                notes={notes}
              />
            ))}
          </ListGroup>
        </Col>

        <Col>
          <h2>
            Suppliers
            <CountBadge count={suppliers?.length} />
          </h2>
          <ListGroup variant="flush">
            {suppliers.map(
              ({
                id,
                supplierType,
                name,
                description,
                addresses,
                contactDetails,
              }) => (
                <SupplierListItem
                  key={id}
                  id={id}
                  supplierType={supplierType}
                  name={name}
                  description={description}
                  addresses={addresses}
                  contactDetails={contactDetails}
                />
              )
            )}
          </ListGroup>
        </Col>
        <Col>
          <h2>
            Contact persons
            <CountBadge count={persons?.length} />
          </h2>
          <ListGroup variant="flush">
            {persons.map((item, i) => (
              <PersonsListItem key={`contactPerson${item?.id}${i}`} {...item} />
            ))}
          </ListGroup>
        </Col>
      </Row>
      <h2>Marketing profile</h2>
      <Row>
        <Col>
          {mp?.properties?.addressBlock ? (
            <div className="my-1">
              <span className="small text-muted">
                {camelCaseToText("addressBlock")}
              </span>
              <div className="lh-sm">{mp?.properties?.addressBlock}</div>
            </div>
          ) : null}
          {mp?.properties?.addressBlockHtml ? (
            <div className="my-1">
              <span className="small text-muted">Address block HTML</span>
              <div className="lh-sm">{mp?.properties?.addressBlockHtml}</div>
            </div>
          ) : null}
        </Col>
        <Col>
          {mp?.properties?.highlights ? (
            <div className="my-1">
              <span className="small text-muted">Highlights</span>
              <div className="lh-sm">{mp?.properties?.highlights}</div>
            </div>
          ) : null}
          {mp?.properties?.psLine ? (
            <div className="my-1">
              <span className="small text-muted">PS line</span>
              <div className="lh-sm">{mp?.properties?.psLine}</div>
            </div>
          ) : null}
        </Col>
        <Col>
          {mp?.properties?.senderName ? (
            <div className="my-1">
              <span className="small text-muted">
                {camelCaseToText("senderName")}
              </span>
              <div className="lh-sm">{mp?.properties?.senderName}</div>
            </div>
          ) : null}
          {mp?.properties?.senderEmail ? (
            <div className="my-1">
              <span className="small text-muted">
                {camelCaseToText("senderEmail")}
              </span>
              <div className="lh-sm">{mp?.properties?.senderEmail}</div>
            </div>
          ) : null}
          {mp?.properties?.aliasEmail ? (
            <div className="my-1">
              <span className="small text-muted">
                {camelCaseToText("aliasEmail")}
              </span>
              <div className="lh-sm">{mp?.properties?.aliasEmail}</div>
            </div>
          ) : null}
          {mp?.properties?.customerEmail ? (
            <div className="my-1">
              <span className="small text-muted">
                {camelCaseToText("customerEmail")}
              </span>
              <div className="lh-sm">{mp?.properties?.customerEmail}</div>
            </div>
          ) : null}
        </Col>
        <Col>
          {mp?.properties?.qrCode ? (
            <div className="my-1">
              <span className="small text-muted">QR code</span>
              <div className="lh-sm">{mp?.properties?.qrCode}</div>
            </div>
          ) : null}
          {mp?.properties?.customerUrl ? (
            <div className="my-1">
              <span className="small text-muted">Customer URL</span>
              <div className="lh-sm">{mp?.properties?.customerUrl}</div>
            </div>
          ) : null}
          {mp?.properties?.clientSurveyUrl ? (
            <div className="my-1">
              <span className="small text-muted">Client survey URL</span>
              <div className="lh-sm">{mp?.properties?.clientSurveyUrl}</div>
            </div>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

const CustomerView = () => {
  const { itemId: id } = useParams();

  const { data: customer, error } = useFetch({
    url: `${config.api.phoenix}/customers/${id}`,
    customerId: id,
  });

  const {
    data: mp,
    // error: mpError,
    // mutate: refreshMp,
  } = useFetch({
    url: `${config.api.phoenix}/marketingprofiles/${id}`,
    customerId: id,
  });

  const {
    data: suppliers,
    // error: mpError,
    // mutate: refreshMp,
  } = useFetch({
    url: `${config.api.phoenix}/customers/${id}/suppliers?pageSize=30`,
    customerId: id,
  });

  const {
    data: persons,
    // error: personsError,
    // mutate: refreshContactDetails,
  } = useFetch({
    url: `${config.api.phoenix}/persons?customerId=${id}&pageSize=30`,
    customerId: id,
  });

  const { hideCollapsed, setHideCollapsed } = useContext(LayoutContext);

  const onHoldDate = customer?.onHold
    ? moment(customer?.onHold).format("D MMM YYYY")
    : "";

  return (
    <Card className="card-main border-0 border-start">
      <Card.Header className="px-2 bg-gray-200">
        <Stack direction="horizontal" gap={2}>
          <Button
            variant="outline-secondary"
            className="px-2"
            size="sm"
            title={`${hideCollapsed ? "Show" : "Hide"} sidebar`}
            onClick={() => setHideCollapsed((curr) => !curr)}
          >
            {hideCollapsed ? <GoSidebarCollapse /> : <GoSidebarExpand />}
          </Button>
          <CustomerExportDropdown
            customer={customer}
            mp={mp}
            suppliers={suppliers}
            persons={persons}
          />
          <span className="flex-grow-1"></span>
          {!!customer && customer?.onHold ? (
            <Badge
              pill
              bg="warning-shade-10"
              title={`On hold since ${onHoldDate}${
                customer?.onHoldDescription
                  ? `\n${customer?.onHoldDescription}`
                  : ""
              }`}
              className="cursor-default"
            >
              <FaRegCirclePause className="me-1" />
              <span className="text-uppercase fw-semibold">on hold</span>
            </Badge>
          ) : null}
          {!!customer && !customer?.active ? (
            <Badge pill bg="danger" className="cursor-default">
              <FaBan className="me-1" />
              <span className="text-uppercase fw-semibold">inactive</span>
            </Badge>
          ) : null}
          <Button
            variant="link"
            className="px-2 link-secondary"
            size="sm"
            as={Link}
            to=".."
            title="Close"
          >
            <BsXLg />
          </Button>
        </Stack>
      </Card.Header>
      <Card.Body>
        <WithLoaderAndError
          isLoading={!customer && !error}
          error={error}
          fullpage
        >
          <CustomerViewInner
            {...customer}
            customer={customer}
            mp={mp}
            suppliers={suppliers}
            persons={persons}
            // mpLogo={mp?.images?.logo?.fileId}
          />
        </WithLoaderAndError>
      </Card.Body>
      <Card.Footer className="px-2 bg-gray-200">xx</Card.Footer>
    </Card>
  );
};

export default CustomerView;
