import React, { useState } from "react";
import { BsImageFill } from "react-icons/bs";
import { getImgUrl } from "../../utils/getImgUrl";
import Loader from "./Loader";
import { config } from "../../config";

const ImgBox = ({
  imgId,
  width,
  height = width,
  noStretch = false,
  ratio = true, // var(--img-box-aspect-ratio)
  className = "",
  padding = 0,
  imgBg,
  iconBg = imgBg,
  imgColor,
  iconColor = imgColor,
  icon: Icon = BsImageFill,
  style = {},
  alt,
  title,
  api = `${config.api.images}/images`,
  src,
  resizeMode = "cover",
  format,
}) => {
  const imgSrc =
    src ||
    getImgUrl(
      imgId,
      {
        width: width,
        height: height,
        resizeMode: resizeMode,
        format: format,
      },
      api
    );

  const contentWidth = noStretch
    ? {
        width: "fit-content",
      }
    : {};

  const boxSize =
    !ratio && width && height
      ? {
          width: width,
          height: height,
        }
      : {};

  const imgBoxStyle = {
    ...(padding ? { padding: padding } : {}),
    background: imgSrc ? imgBg : iconBg || null,
    color: imgSrc ? imgColor : iconColor || null,
    ...contentWidth,
  };

  // const [isLoading, setIsLoading] = useState(!!imgSrc);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  return (
    <div
      className={`img-box 
      ${isError ? "has-error" : isLoading ? "is-loading" : ""} ${className}`}
      style={{ ...contentWidth, ...imgBoxStyle, ...style }}
      title={isError ? "Image not found" : title}
    >
      <div
        className={`img-box-inner ${!!ratio ? "ratio" : ""}`}
        style={ratio ? { "--img-box-aspect-ratio": ratio } : boxSize}
      >
        {isLoading ? (
          <Loader />
        ) : isError ? (
          <Icon className="icon error-icon" />
        ) : null}

        {imgSrc ? (
          <img
            src={imgSrc}
            alt={alt || imgSrc}
            className={`img-box-img img-${
              resizeMode.toLowerCase() === "contain" ? "contain" : "cover"
            }`}
            onLoad={() => {
              setIsLoading(false);
            }}
            onError={() => {
              setIsLoading(false);
              setIsError(true);
            }}
          />
        ) : (
          <Icon className="icon" />
        )}
      </div>
    </div>
  );
};

export default ImgBox;
