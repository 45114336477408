import { useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
/**
 * Handles smooth scrolling to the element with id equal to the value of the 'pageId' parameter in the route.
 * If the 'hash' prop is true, it will use the hash from the URL as the id instead.
 * @param {object} props - Component props
 * @param {boolean} [props.hash=false] - Whether to use the hash from the URL as the id
 * @param {string} [props.paramName="pageId"] - The name of the parameter in the route to use as the id
 * @returns {null} - This component does not render anything
 */
const ScrollToAnchor = ({ hash = false, paramName = "pageId" }) => {
  const location = useLocation();
  const lastHash = useRef("");

  const params = useParams();
  const pageId = params?.[paramName];

  useEffect(() => {
    if (!!hash && location.hash) {
      lastHash.current = location.hash.slice(1); // for further use after navigation
    } else if (!hash && pageId) {
      lastHash.current = pageId;
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: "smooth", block: "start" });
        lastHash.current = "";
      }, 100);
    }
  }, [location, pageId, hash]);

  return null;
};

export default ScrollToAnchor;
