import React, { useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import worker from "../../assets/il-worker.svg";
import surprise from "../../assets/il-surprise.svg";
import doctor from "../../assets/il-doctor.svg";
import rawr from "../../assets/il-rawr.svg";
import science from "../../assets/il-scientist.svg";
import wizard from "../../assets/wizard.png";
import {
  BsArrowLeft,
  BsArrowLeftRight,
  BsBoxArrowRight,
  BsBraces,
  BsBug,
  BsInfoCircle,
  BsInfoCircleFill,
} from "react-icons/bs";
import { useAuthContext } from "../../context/AuthContext";
import { useDrawer } from "../../context/DrawerContext";
import { Link } from "react-router-dom";
import DropdownItem from "react-bootstrap/esm/DropdownItem";

const Error = ({
  error,
  fullPage = false,
  pageWithHeader = false,
  navigateTo,
  setErrors,
  errorIndex = 0,
}) => {
  const [showFullError, setShowFullError] = useState(false);

  const { logout, client, user } = useAuthContext();
  const { handleShowOffCanvas, setQuery, setShowSelectClient } = useDrawer();

  const handleReportBug = () => {
    handleShowOffCanvas();
    setQuery("issue");
  };

  const closeToast = () =>
    setErrors &&
    setErrors((prevSubmitErrors) =>
      prevSubmitErrors.map((item, itemIndex) =>
        itemIndex === errorIndex
          ? {
              ...item,
              show: false,
            }
          : item
      )
    );

  const setAutohideToast = (val) =>
    setErrors &&
    setErrors((prevSubmitErrors) =>
      prevSubmitErrors.map((item, itemIndex) =>
        itemIndex === errorIndex
          ? {
              ...item,
              autohideToast: val,
              show: !val ? true : item.show,
            }
          : item
      )
    );

  const withCloseToast = (action) => {
    !!closeToast && closeToast();
    action();
  };

  const withAutohideToast = (action, val) => {
    !!setAutohideToast && setAutohideToast(val);
    action();
  };

  const errorContent =
    error.name === "AxiosError"
      ? {
          code: error?.response?.status || error.code,
          title:
            error?.response?.status === 403
              ? "Access unauthorized"
              : "Something went wrong",
          text:
            error?.response?.status === 403
              ? "Current client or user is not authorized to access this content"
              : error?.message || "Something went wrong",
          errorText:
            error?.response?.status === 403
              ? `Access unauthorized - ${
                  client?.code
                    ? `Client: ${client?.code}`
                    : client?.id
                    ? `Client ID: ${client?.id}`
                    : "Unknown client"
                } | ${
                  client?.code
                    ? `User: ${user?.name}`
                    : user?.name
                    ? `User ID: ${user?.subject}`
                    : "User: unknown"
                }`
              : !!error?.response?.statusText
              ? "Status text: " + error?.response?.statusText
              : typeof error?.response?.data === "string"
              ? error?.response?.data
              : null,
          errors:
            typeof error?.response?.data?.errors === "object"
              ? error?.response?.data?.errors
              : null,
          url: error?.config?.url,
          img:
            error?.response?.status === 403
              ? wizard
              : error?.response?.data?.errors === "object"
              ? science
              : typeof error?.response?.data === "string"
              ? doctor
              : surprise,
        }
      : error === "404"
      ? {
          title: "Page not found",
          text: "Sorry, this page doesn't exist ( yet :)",
          url: window.location.href,
          img: worker,
        }
      : {
          title: error?.title || "You broke the app!",
          text: error?.text || "Sorry, an unexpected error occurred",
          action: "feedback",
          img: rawr,
        };

  const ErrorDetailsBlock = ({ label, content = "", contentBlock = null }) => {
    if (!content && !contentBlock) {
      return null;
    }
    return (
      <div className="my-1 font-monospace">
        {label ? (
          <span className="me-2 fw-semibold text-muted">{label}</span>
        ) : null}
        {!!content ? (
          <span className="text-secondary text-wrap text-break fs-85 lh-sm">
            {content}
          </span>
        ) : null}
        {!!contentBlock ? contentBlock : null}
      </div>
    );
  };

  return (
    <div
      className={`mx-auto p-3 fade-in text-center ${
        fullPage ? "h-100 d-flex flex-column justify-content-center" : ""
      } ${
        pageWithHeader
          ? "page-with-header d-flex flex-column justify-content-center"
          : ""
      }`}
      style={{ maxWidth: "500px" }}
    >
      <div className="mx-auto mb-4  " style={{ maxWidth: "160px" }}>
        <img
          src={errorContent.img}
          alt="error"
          width={160}
          className="m-auto mw-100 fade-in-grow-up delay"
        />
      </div>

      <div className="delay-each-child">
        {errorContent?.title ? (
          <h3 className="fade-in-up delay">{errorContent?.title}</h3>
        ) : null}
        {errorContent?.text ? (
          <p className="fade-in-up delay mb-2">{errorContent.text}</p>
        ) : null}
      </div>

      <div className="mt-2 delay-each-child d-flex justify-content-center">
        <Button
          as={Link}
          to={navigateTo?.url || "/"}
          variant="link"
          className="mx-auto fade-in-up delay"
        >
          <BsArrowLeft className="me-2" />
          {navigateTo?.title
            ? `Back to ${navigateTo?.title}`
            : `Back to dashboard`}
        </Button>

        <Dropdown
          className="mx-auto"
          onToggle={(nextShow) =>
            setAutohideToast && setAutohideToast(!nextShow)
          }
        >
          <Dropdown.Toggle variant="link">More...</Dropdown.Toggle>
          <Dropdown.Menu>
            <DropdownItem
              as="button"
              type="button"
              onClick={() => withCloseToast(() => setShowSelectClient(true))}
            >
              <BsArrowLeftRight className="me-1" /> Switch client
            </DropdownItem>
            <Dropdown.Item as="button" type="button" onClick={logout}>
              <BsBoxArrowRight className="me-1" /> Re-login
            </Dropdown.Item>
            <DropdownItem
              as="button"
              type="button"
              onClick={() => withCloseToast(handleReportBug)}
            >
              <BsBug className="me-2" />
              Report a bug
            </DropdownItem>

            <Dropdown.Divider />

            <DropdownItem
              as="button"
              type="button"
              onClick={() => setShowFullError(true)}
              disabled={typeof error !== "object"}
            >
              <BsInfoCircle className="me-2" />
              View error details
            </DropdownItem>
            <DropdownItem
              as="button"
              type="button"
              onClick={() => console.log(error)}
            >
              <BsBraces className="me-2" />
              Log error to console
            </DropdownItem>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <Modal
        show={!!showFullError}
        onHide={() => withAutohideToast(() => setShowFullError(false), true)}
        onShow={() => setAutohideToast && setAutohideToast(false)}
        centered
        fullscreen="mo-down"
        scrollable
        size="lg"
        backdropClassName="error-modal-z-index"
        className="error-modal-z-index"
      >
        <Modal.Header closeButton className="py-1">
          <Modal.Title>
            <BsInfoCircleFill className="opacity-50 me-2" />
            Error details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light px-4">
          <ErrorDetailsBlock
            label="URL"
            contentBlock={
              <span className="text-success-shade-25 text-wrap text-break fs-85 lh-sm">
                {error?.config?.url}
              </span>
            }
          />
          <ErrorDetailsBlock label="Code" content={error?.code} />
          <ErrorDetailsBlock label="Message" content={error?.message} />
          {error?.request && !error?.response ? (
            <div className="mt-2">
              <ErrorDetailsBlock
                label="Request"
                contentBlock={
                  <div className="ps-2">
                    <ErrorDetailsBlock
                      label="status"
                      content={error?.request?.status}
                    />
                    <ErrorDetailsBlock
                      label="statusText"
                      content={error?.request?.statusText}
                    />
                    <ErrorDetailsBlock
                      label="withCredentials"
                      content={
                        error?.request?.withCredentials ? (
                          <span className="text-success">true</span>
                        ) : (
                          <span className="text-danger">false</span>
                        )
                      }
                    />
                  </div>
                }
              />
            </div>
          ) : null}
          {error?.response ? (
            <div className="mt-2">
              <ErrorDetailsBlock
                label="Response"
                contentBlock={
                  <div className="ps-2">
                    <ErrorDetailsBlock
                      label="status"
                      content={error?.response?.status}
                    />
                    <ErrorDetailsBlock
                      label="statusText"
                      content={error?.response?.statusText}
                    />
                    <ErrorDetailsBlock
                      label="data"
                      contentBlock={
                        <div className="ps-2">
                          <ErrorDetailsBlock
                            label="title"
                            content={error?.response?.data?.title}
                          />
                          {!!error?.response?.data?.errors ||
                          !!error?.response?.data?.[0] ? (
                            <code>
                              <pre className="bg-light-shade-5 p-2 mt-2 rounded">
                                {JSON.stringify(
                                  error?.response?.data?.[0]
                                    ? error?.response?.data
                                    : error?.response?.data?.errors,
                                  null,
                                  2
                                )}
                              </pre>
                            </code>
                          ) : null}
                        </div>
                      }
                    />
                  </div>
                }
              />
            </div>
          ) : null}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Error;
