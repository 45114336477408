import React, { useEffect, useRef, useState } from "react";
import { Button, Carousel } from "react-bootstrap";
import {
  BsArrowClockwise,
  BsBoxArrowUpRight,
  BsX,
  BsZoomIn,
  BsZoomOut,
  BsFullscreen,
  BsFullscreenExit,
} from "react-icons/bs";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

const VariantDMProof = ({
  proofs,
  format,
  setScale,
  flipPosition,
  handleFlip,
  scale,
  resetFlip,
  handleOpenInTab,
}) => {
  const proofUrls = proofs.flatMap((doc) => doc.urls);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const containerRef = useRef(null);

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      if (containerRef.current.requestFullscreen) {
        containerRef.current.requestFullscreen();
      } else if (containerRef.current.webkitRequestFullscreen) {
        /* Safari */
        containerRef.current.webkitRequestFullscreen();
      } else if (containerRef.current.msRequestFullscreen) {
        /* IE11 */
        containerRef.current.msRequestFullscreen();
      }
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
      setIsFullscreen(false);
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(document.fullscreenElement === containerRef.current);
    };
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className={`zoom-image-wrap h-100 ${isFullscreen ? "fullscreen" : ""}`}
    >
      <div className=" w-100 carousel h-100">
        {format === "jpg" ? (
          <Carousel data-bs-theme="dark" controls indicators interval={null}>
            {proofUrls.map((url) => (
              <Carousel.Item key={url}>
                <div
                  style={{
                    position: "absolute",
                    top: 20,
                    left: -55,
                  }}
                ></div>
                <TransformWrapper
                  initialScale={1}
                  centerOnInit
                  minScale={0.5}
                  onTransformed={(ref, state) => {
                    setScale(state?.scale);
                  }}
                  className="h-100"
                >
                  {({
                    zoomIn,
                    zoomOut,
                    resetTransform,
                    setTransform,
                    ...rest
                  }) => {
                    return (
                      <React.Fragment>
                        <div
                          className="abs-buttons"
                          style={{
                            right: 0,
                            left: "auto",
                            flexDirection: "column",
                          }}
                        >
                          <Button
                            variant="secondary"
                            onClick={() => handleOpenInTab(url)}
                          >
                            <BsBoxArrowUpRight />
                          </Button>
                          <Button
                            onClick={toggleFullscreen}
                            variant="secondary"
                          >
                            {isFullscreen ? (
                              <BsFullscreenExit />
                            ) : (
                              <BsFullscreen />
                            )}
                          </Button>
                          <Button onClick={handleFlip}>
                            <BsArrowClockwise
                              style={{
                                transform: `rotate(${flipPosition * 90}deg)`,
                              }}
                            />
                          </Button>
                          <Button
                            onClick={() => zoomIn()}
                            style={{ flexShrink: 0 }}
                            disabled={scale === 8}
                          >
                            <BsZoomIn />
                          </Button>
                          <Button
                            onClick={() => zoomOut()}
                            style={{ flexShrink: 0 }}
                            disabled={scale === 0.5}
                          >
                            <BsZoomOut />
                          </Button>

                          <Button
                            onClick={() => {
                              resetFlip();
                              resetTransform();
                            }}
                            style={{ flexShrink: 0 }}
                            disabled={scale === 1 && flipPosition === 0}
                          >
                            <BsX />
                          </Button>
                        </div>

                        <TransformComponent>
                          <div
                            className="canvas"
                            style={
                              flipPosition === 1 || flipPosition === 3
                                ? { maxHeight: "80vh" }
                                : {}
                            }
                          >
                            <div className="placeholder" />
                            <img
                              className="proof-img"
                              src={url}
                              alt={url}
                              style={{
                                transform: `rotate(${flipPosition * 90}deg)`,
                                maxWidth:
                                  flipPosition === 1 || flipPosition === 3
                                    ? "calc(80vh - 2em)"
                                    : "100%",
                                maxHeight: "100%",
                              }}
                            />
                          </div>
                        </TransformComponent>
                      </React.Fragment>
                    );
                  }}
                </TransformWrapper>
              </Carousel.Item>
            ))}
          </Carousel>
        ) : (
          proofs.map((doc) =>
            doc.urls.map((url) => (
              <div
                key={`pdf-${url}`}
                className={`${isFullscreen ? "fullscreen" : ""}`}
              >
                <div style={{ position: "absolute", left: -55, top: 20 }}>
                  <Button
                    className="mb-4"
                    variant="secondary"
                    onClick={() => handleOpenInTab(url)}
                  >
                    <BsBoxArrowUpRight />
                  </Button>
                </div>
                <div
                  style={
                    isFullscreen
                      ? {
                          position: "absolute",
                          zIndex: 1000,
                          top: 60,
                          right: 510,
                        }
                      : { position: "absolute", left: -55, top: 60 }
                  }
                >
                  <Button variant="secondary" onClick={toggleFullscreen}>
                    {isFullscreen ? <BsFullscreenExit /> : <BsFullscreen />}
                  </Button>
                </div>

                <iframe
                  className="w-100"
                  title={`Proof PDF`}
                  src={url}
                  alt={url}
                  style={{
                    minHeight: "600px",

                    display: "block",
                    transform: `rotate(${flipPosition * 90}deg)`,
                  }}
                />
              </div>
            ))
          )
        )}
      </div>
    </div>
  );
};

export default VariantDMProof;
