import React, { useState } from "react";
import ListPage from "../ui/ListPage";
import { config } from "../../config";
import StatusBadge from "../custom/StatusBadge";
import {
  Badge,
  Button,
  Card,
  Col,
  OverlayTrigger,
  Popover,
  Row,
  Stack,
} from "react-bootstrap";
import {
  BsBox,
  BsBoxes,
  BsCalendarWeekFill,
  BsCart4,
  BsCheck2,
  BsClockFill,
  BsFunnelFill,
  BsMegaphoneFill,
} from "react-icons/bs";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ImgBox from "../ui/ImgBox";
import formatDate from "../../utils/formatDate";

const Orders = () => {
  const [state, setState] = useState("");
  const STATE_OPTIONS = ["new", "open", "pending", "closed", "final"];

  const StateSelect = () => {
    return (
      <Dropdown as={ButtonGroup} className="filter-menu">
        <Dropdown.Toggle
          variant={"outline-secondary"}
          size="sm"
          active={!!state}
          title="filter"
        >
          <BsFunnelFill />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {STATE_OPTIONS.map((option) => (
            <Dropdown.Item
              as="span"
              className="cursor-pointer"
              onClick={() =>
                setState((curr) => (curr === option ? "" : option))
              }
              active={state === option}
              key={option}
            >
              <BsCheck2
                className={`${
                  state !== option ? "opacity-0" : ""
                } text-success me-2`}
              />
              <StatusBadge value={option} />
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const DetailItem = ({ item }) => {
    const navigate = useNavigate();
    const handleNavigate = () => {
      navigate(`/programs/campaign/${item.programId}`);
    };

    return (
      <Card className="detail-card d-flex w-100 h-100">
        <Row style={{ height: "100%" }}>
          <Stack style={{ minWidth: "84%", height: "100%" }} className="d-flex">
            <Link
              className="text-decoration-none hover-parent d-flex align-items-center py-2 h-100 order-item"
              style={{ color: "black" }}
              to={`/orders/${item.id}`}
            >
              <Card.Body className=" h-100">
                <Row className="mb-1 detail-header-row">
                  <h5 className="detail-header justify-content-between d-flex">
                    <div>{`Order ${item.id}`}</div>
                    <div>{item.programName}</div>
                    <div>
                      <StatusBadge value={item.state} />
                    </div>
                  </h5>
                </Row>
                <Row className="mb-3 detail-items-row">
                  {item.modified && (
                    <small style={{ width: "33%", fontSize: "12px" }}>
                      <BsClockFill color="grey" /> {" Last modified: "}
                      {formatDate(item.modified)}
                    </small>
                  )}
                  {item.orderDate && (
                    <small style={{ width: "33%", fontSize: "12px" }}>
                      <BsCart4 /> {" Order date: "}
                      {formatDate(item.orderDate)}
                    </small>
                  )}
                  {item.participationDate && (
                    <small style={{ width: "34%", fontSize: "12px" }}>
                      <BsCalendarWeekFill color="grey" /> {" Campaign date: "}
                      {formatDate(item.participationDate)}
                    </small>
                  )}
                </Row>

                {item.packageItems.length > 0 && (
                  <Row className="mb-3 detail-items-row">
                    <h5 className="mb-2" style={{ fontSize: "1em" }}>
                      <BsBoxes color="grey" />
                      {" Packages "}
                    </h5>
                    {item.packageItems.map((product) => (
                      <div key={product.id} className="package-item">
                        <h4 style={{ fontSize: "14px" }}>
                          {`Package ID ${product.packageId}`}
                        </h4>
                        {product.products.map((productItem) => (
                          <div key={productItem.id}>
                            {!!productItem.amount && (
                              <span
                                style={{ fontSize: "13px", marginLeft: "10px" }}
                              >
                                {productItem.amount} x{" "}
                              </span>
                            )}
                            {!!productItem.productName && (
                              <span
                                style={{ fontSize: "13px", marginLeft: "10px" }}
                              >
                                {productItem.productName}
                              </span>
                            )}
                          </div>
                        ))}
                      </div>
                    ))}
                  </Row>
                )}
                {item.productItems.length > 0 && (
                  <Row className="mb-3 detail-items-row">
                    <h4 className="mb-2" style={{ fontSize: "1em" }}>
                      <BsBox color="grey" />
                      {" Products "}
                    </h4>
                    {item.productItems.map((product) => (
                      <div key={product.id} className="product-item">
                        <h4 style={{ fontSize: "14px" }}>
                          {`Product ID ${product.productId}`}
                        </h4>
                        {product.amount > 0 && (
                          <span
                            style={{ fontSize: "13px", marginLeft: "10px" }}
                          >
                            {product.amount} x{" "}
                          </span>
                        )}
                        {product.productName && (
                          <span
                            style={{ fontSize: "13px", marginLeft: "10px" }}
                          >
                            {product.productName}
                          </span>
                        )}
                      </div>
                    ))}
                  </Row>
                )}
              </Card.Body>
            </Link>
            <Card.Footer className="d-flex">
              <div className="w-100">
                <Row className="w-100 d-flex">
                  <h4 className="text-center" style={{ fontSize: "15px" }}>
                    <BsMegaphoneFill />
                    {" Campaign "}
                  </h4>
                </Row>
                <Row
                  className="d-flex w-100 detail-items-row"
                  style={{ borderTop: "1px solid #ccc", paddingTop: "8px" }}
                >
                  <Col style={{ maxWidth: "16%" }}>
                    <div className="position-relative w-100">
                      <ImgBox
                        imgId={item.program.thumbnail}
                        noStretch
                        width={90}
                        whiteBox
                        whiteBoxWidth={90}
                        className="fade-in-grow delay m-n1"
                      />
                    </div>
                  </Col>
                  <Col>
                    <h5 style={{ fontSize: "14px" }}>
                      <div>{item.programName}</div>
                      <div
                        style={{ marginTop: "3px" }}
                      >{`Program ID: ${item.programId}`}</div>
                      <Button
                        className=" d-flex py-2 h-100 campaign-item"
                        variant="link"
                        onClick={() => handleNavigate()}
                      >
                        Visit
                      </Button>
                    </h5>
                  </Col>
                </Row>
              </div>
            </Card.Footer>
          </Stack>
        </Row>
      </Card>
    );
  };

  return (
    <ListPage
      baseUrl={`${config.api.order}/orders`}
      urlParams={state ? `&state=${state}` : ""}
      itemLabel="order"
      itemPath="orders"
      thumbnailCol={"program.thumbnail"}
      nameCol={null}
      detailItem={DetailItem}
      tableItems={{
        "program.thumbnail": {},
        id: {},
        programId: {
          displayHeading: "Program ID",
        },
        programName: {
          displayHeading: "Program name",
        },
        participationDate: {
          displayHeading: (
            <>
              Participation
              <BsCalendarWeekFill className="text-secondary-tint-15 mx-2" />
            </>
          ),
        },
        customerId: {
          displayHeading: "Customer ID",
        },

        "customer.name": {},
        productItems: {
          displayHeading: "Items",
          cellClass: "text-center",
          mergeWith: "packageItems",
          component: ({ value: productItems, extraValue: packageItems = [] }) =>
            productItems.length + packageItems.length ? (
              <OverlayTrigger
                placement="left"
                overlay={
                  <Popover id="packProd">
                    <Popover.Body>
                      {productItems.length ? (
                        <div className="mb-2">
                          <h4 className="fw-semibold fs-6 mb-2">Products</h4>
                          {productItems.map((item, i) => (
                            <div
                              key={item.productId}
                              className="text-nowrap text-truncate font-monospace min-w-100 fs-90 py-1"
                              style={{ borderTop: "2px dotted #ddd" }}
                            >
                              <span className="font-monospace">
                                {item.amount}
                              </span>{" "}
                              <span className="font-monospace text-muted">
                                x
                              </span>{" "}
                              <span>{item.productName}</span>
                            </div>
                          ))}
                        </div>
                      ) : null}
                      {packageItems.length ? (
                        <div>
                          <h4 className="fw-semibold fs-6 mb-2">Packages</h4>
                          {!!packageItems &&
                            packageItems.map((item, i) => (
                              <div
                                key={item.packageId}
                                className="text-nowrap text-truncate font-monospace min-w-100 fs-90 py-1"
                                style={{ borderTop: "2px dotted #ddd" }}
                              >
                                <span>
                                  {item.packageName || "Package " + item.id}
                                </span>
                              </div>
                            ))}
                        </div>
                      ) : null}
                    </Popover.Body>
                  </Popover>
                }
              >
                <Badge pill className="font-monospace bg-primary-tint-25">
                  {productItems.length + packageItems.length}
                </Badge>
              </OverlayTrigger>
            ) : null,
        },
        state: {
          component: ({ value }) => <StatusBadge value={value} />,
          cellClass: "text-center",
        },
        orderDate: {
          displayHeading: (
            <>
              Order
              <BsCalendarWeekFill className="text-secondary-tint-15 mx-2" />
            </>
          ),
        },
        modified: {
          displayHeading: (
            <>
              Modified
              <BsCalendarWeekFill className="text-secondary-tint-15 mx-2" />
            </>
          ),
        },
        program: { hidden: true },
        packageItems: {
          hidden: true,
        },
      }}
      layoutOptions={["detail", "table"]}
      headerItems={<StateSelect />}
      searchParam={null}
      orderOptions={null}
      colorClass="order"
    />
  );
};

export default Orders;
