import { Card, Table } from "react-bootstrap";
import Moment from "react-moment";

const VariantApprovalHistory = ({ records }) => {
  return !!records && records.length > 0 ? (
    <Card className="h-100 bg-dark bg-opacity-10">
      <Table responsive hover striped>
        <thead>
          <tr>
            <th></th>
            <th>{"Approval type"}</th>
            <th className="text-center">{"index"}</th>
            <th></th>
            <th>{"Approval timestamp"}</th>
            <th>{"Approved by user"}</th>
            <th>{"Approved by username"}</th>
            <th>{"Description"}</th>
          </tr>
        </thead>
        <tbody>
          {records.map((item, index) => (
            <tr key={index}>
              <td></td>
              <td>{item.approvalType}</td>
              <td className="text-center">{item.index}</td>
              <td></td>
              <td>
                {item.approved !== null ? (
                  <Moment format="DD MMM YYYY HH:mm">{item.approved}</Moment>
                ) : null}
              </td>

              <td>{item.approvedBy}</td>
              <td>{item.approvedByName}</td>
              <td>{item.description}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  ) : (
    <Card className="h-100">
      <Card.Body className="align-items-center d-flex justify-content-center">
        <span>{"No approval history recorded"}</span>
      </Card.Body>
    </Card>
  );
};

export default VariantApprovalHistory;
