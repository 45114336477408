/*
handles logic for creating the right message for the toasts. Based on the status code of the error, a certain message will appear
*/
const createDetailedErrorMessage = (error) => {
    if(error?.message === "Request failed with status code 504"){
        return "It looks like the server is taking too long to respond. Please try again in a few moments. If the problem persists, please contact us for assistence"
    }
    else if(error?.message === "Request failed with status code 400"){
        return "Your request couldn't be processed. Please make sure you're using the parameters correctly."
    }
    else if(error?.message === "Request failed with status code 500"){
        return "Somethig went wrong in the internal server. Please try again later. If the problem persists, please contact us for assistence"
    }
    else if(error?.message === "Request failed with status code 404"){
        return "Page not found. It might not exist yet, or perhaps the URL was entered incorrectly"
    }
    else if(error?.message === "Request failed with status code 403"){
        return "Access unauthorized. Current client or user is not authorized to access this content"
    }
    else if(error?.message === 'Request failed with status code 409'){
        let message
        if(error?.request?.response){
            message = error.request.response
        }
        else{
            message = "The server could not complete the HTTP request because it conflicts with the current state of the target resource"
        }
        return message;
    }
    return "An unexpected error occurred";
  };

  export default createDetailedErrorMessage;