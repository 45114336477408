import React from "react";
import { Button, ListGroupItem } from "react-bootstrap";
import { BsPencil } from "react-icons/bs";
import AddressBlock from "./AddressBlock";
import AddressIcon from "./AddressIcon";

const AddressListItem = ({
  item,
  className,
  multiline = false,
  to, // as Link
  onClick, // as Button
  ...rest
}) => {
  const linkProps = to ? { to } : {};
  const btnProps = onClick ? { onClick } : {};

  return (
    <ListGroupItem
      action={to || onClick}
      className={`hover-parent d-flex justify-content-start align-items-center${
        className ? ` ${className}` : ""
      }`}
      {...linkProps}
      {...btnProps}
      {...rest}
    >
      <AddressIcon
        type={item?.type}
        className={`fs-5 text-secondary-tint-10${
          multiline ? " ms-1 me-3 my-2 align-self-start" : " me-2 ms-n1"
        }`}
        title={`${item?.type} address`}
      />
      <AddressBlock address={item} multiline={multiline} />

      {to ? (
        <Button
          as="span"
          variant="link"
          className={`hover-opacity ${
            multiline ? " align-self-end me-n1" : " my-n2 me-n3"
          }`}
          title="Edit"
        >
          <BsPencil />
        </Button>
      ) : null}
    </ListGroupItem>
  );
};

export default AddressListItem;
