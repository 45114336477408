import React from "react";
import ExampleWrap from "./ExampleWrap";
import ImgBox from "../../ui/ImgBox";
import { Col, Row } from "react-bootstrap";

const EImgBox = () => {
  return (
    <Row className="g-2" sm={4}>
      <Col>
        <ImgBox imgId={"azsAI6KBHQdvESCHxsbDS7vEY1p-d2ZF"} width={200} />
      </Col>
      <Col>
        <ImgBox imgId={"UxQ516SjnaMXOL0A7KUaFbSAeOkhzg75"} width={200} />
      </Col>
      <Col>
        <ImgBox
          imgId={"4e4cc820-cfa8-493f-b4ec-024a6bef0ba7"}
          width={200}
          ratio="65%"
          resizeMode="contain"
          padding={10}
        />
      </Col>
      <Col>
        <ImgBox
          imgId={"M7eDnGfjJeK1xD0H_cxFcaMQYta79VBc"}
          width={200}
          ratio="65%"
          resizeMode="contain"
          padding={10}
        />
      </Col>
    </Row>
  );
};

const ExampleImgBox = ({ id, title }) => {
  return <ExampleWrap component={EImgBox} id="imgbox" title={title} />;
};

export default ExampleImgBox;
