import { ToastContainer, Toast } from "react-bootstrap";

const ToastHandler = ({ toasts, setToasts }) => {
  return (
    <ToastContainer
      position="bottom-end"
      className="position-fixed pe-3"
      style={{
        marginBottom: "calc(var(--footer-height)",
        padding: ".75rem",
      }}
    >
      {toasts?.map((toast) => (
        <Toast
          key={toast.id}
          onClose={() =>
            setToasts((curr) =>
              curr.filter((oldToast) => oldToast.id !== toast.id)
            )
          }
          className={`color-light bg-${toast?.variant || "danger"}-tint-10`}
          show
          delay={toast?.delay || 2000}
          autohide
        >
          {/* 
              id
              variant
              heading -> title
              delay
              text  -> message
              add img
            */}
          <Toast.Header closeVariant="white" className="pb-0">
            <strong className="me-auto fw-semibold">
              {toast.heading || (toast?.variant === "danger" ? "Error" : "")}
            </strong>
            <small className="font-monospace text-opacity-75">
              {toast?.code || ""}
            </small>
          </Toast.Header>
          <Toast.Body className="pt-0 small">
            <div>
              {toast?.text ||
                (toast?.variant === "danger"
                  ? "An unexpected error occurred"
                  : "")}
            </div>
            {toast.img ? (
              <img
                src={toast.img}
                alt={toast?.heading}
                className="fade-in-grow-up delay-500ms"
                style={{
                  position: "absolute",
                  width: "80px",
                  top: "-75px",
                  left: "15px",
                }}
              />
            ) : null}
            {!!toast?.response?.data && Array.isArray(toast.response.data) ? (
              <div className="px-3 py-1 mt-2 bg-danger bg-opacity-25">
                {toast.response.data.map((errorLine, i) => (
                  <code key={i} className="d-block my-2">
                    {errorLine}
                  </code>
                ))}
              </div>
            ) : null}
          </Toast.Body>
        </Toast>
      ))}
    </ToastContainer>
  );
};

export default ToastHandler;
