import React from "react";
import { DropdownItem } from "react-bootstrap";

const DropdownItemWithText = ({
  icon: Icon,
  title,
  extraText,
  variant = "secondary",
  titleFontWeight = "nomral",
  disabled,
  children,
  ...rest
}) => {
  return (
    <DropdownItem {...rest} className={disabled ? "opacity-75" : ""}>
      {Icon ? <Icon className={`me-2 text-${variant} opacity-75`} /> : null}
      <span className={`text-${variant} fw-${titleFontWeight}`}>{title}</span>
      {extraText ? (
        <span className={`d-block fs-80 text-muted ${Icon ? "ms-4" : ""}`}>
          {extraText}
        </span>
      ) : null}
      {children}
    </DropdownItem>
  );
};

export default DropdownItemWithText;
