import {
  BsCardText,
  BsEnvelope,
  BsEnvelopeAt,
  BsEnvelopeCheck,
  BsGlobe,
  BsPersonCheck,
  BsPhone,
  BsTelephone,
} from "react-icons/bs";
import { FaGooglePlusG, FaXTwitter } from "react-icons/fa6";
import { SlLink, SlSocialFacebook, SlSocialLinkedin } from "react-icons/sl";
import { LiaFaxSolid } from "react-icons/lia";
import { AiOutlineInstagram, AiOutlineYoutube } from "react-icons/ai";
import { GoPaperclip } from "react-icons/go";

// "Telefoon nr.",
// "Mobiel nr.",
// "Email",
// "Afspraak url",
// "Fax",
// "Facebook",
// "Twitter",
// "LinkedIn",
// "Instagram",
// "YouTube",
// "Bijlage",
// "Website",
// "Google+",
// "Werkplaatsafspraken URL",
// "Bandenwisselafspraak URL",
// "Email afzenderadres",
// "Email afzendernaam",
// "Occasion URL",
// "Volvo Card Telefoon nr.",
// "Email DatabaseNotifications",
// "Overig",

const ContactTypeIcon = ({ type, ...rest }) => {
  if (type === "Telefoon nr." || type === "Volvo Card Telefoon nr.") {
    return <BsTelephone {...rest} />;
  } else if (type === "Mobiel nr.") {
    return <BsPhone {...rest} />;
  } else if (type === "Email") {
    return <BsEnvelope {...rest} />;
  } else if (
    type === "Afspraak url" ||
    type === "Werkplaatsafspraken URL" ||
    type === "Bandenwisselafspraak URL" ||
    type === "Occasion URL"
  ) {
    return <SlLink {...rest} />;
  } else if (type === "Fax") {
    return <LiaFaxSolid {...rest} />;
  } else if (type === "Facebook") {
    return <SlSocialFacebook {...rest} />;
  } else if (type === "Twitter") {
    return <FaXTwitter {...rest} />;
  } else if (type === "LinkedIn") {
    return <SlSocialLinkedin {...rest} />;
  } else if (type === "Instagram") {
    return <AiOutlineInstagram {...rest} />;
  } else if (type === "YouTube") {
    return <AiOutlineYoutube {...rest} />;
  } else if (type === "Bijlage") {
    return <GoPaperclip {...rest} />;
  } else if (type === "Website") {
    return <BsGlobe {...rest} />;
  } else if (type === "Google+") {
    return <FaGooglePlusG {...rest} />;
  } else if (type === "Email afzenderadres") {
    return <BsEnvelopeAt {...rest} />;
  } else if (type === "Email afzendernaam") {
    return <BsPersonCheck {...rest} />;
  } else if (type === "Email DatabaseNotifications") {
    return <BsEnvelopeCheck {...rest} />;
  } else {
    return <BsCardText {...rest} />;
  }
};

export default ContactTypeIcon;
