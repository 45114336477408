import React from "react";
import { Badge } from "react-bootstrap";

const CountBadge = ({
  count,
  successBg = "success-tint-50",
  defaultBg = "secondary-tint-75",
  ...rest
}) => {
  return (
    <span className="fs-65 ms-1 align-text-top">
      <Badge pill bg={count ? successBg : defaultBg} {...rest}>
        {count}
      </Badge>
    </span>
  );
};

export default CountBadge;
