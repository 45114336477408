import React from "react";
import SpacedItem from "../../SpacedItem";

const AddressBlock = ({ address, multiline, className, ...rest }) => {
  return (
    <address
      className={`mb-0 flex-grow-1${className ? ` ${className}` : ""}`}
      {...rest}
    >
      {address?.name ? (
        <span
          className={`fw-semibold text-secondary me-2${
            multiline ? " d-block" : ""
          }`}
        >
          {address?.name}
        </span>
      ) : null}

      <span className="small">
        <span className={`${multiline ? "d-block" : ""}`}>
          <>{address?.street}</>
          <SpacedItem>{address?.houseNumber}</SpacedItem>
          <SpacedItem>{address?.suffix}</SpacedItem>
        </span>
        <span className={`${multiline ? "d-block" : ""}`}>
          <SpacedItem>{address?.postalCode}</SpacedItem>
          <SpacedItem>{address?.city}</SpacedItem>
        </span>
        <span className={`${multiline ? "d-block" : ""}`}>
          <SpacedItem>{address?.province}</SpacedItem>
          <SpacedItem>{address?.country}</SpacedItem>
        </span>
      </span>
    </address>
  );
};

export default AddressBlock;
