import React, { useState } from "react";
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  Row,
  Toast,
  ToastContainer,
  ListGroup,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { BsCheck2, BsPencil, BsX } from "react-icons/bs";
import { config } from "../../../../config";
import fetcher from "../../../../utils/fetcher";
import ImgBox from "../../../ui/ImgBox";
import { useAuthContext } from "../../../../context/AuthContext";
import LabeledItem from "../../../ui/LabeledItem";

const VariantForm = ({ variant, refetch }) => {
  const [editable, setEditable] = useState(false);
  const [error, setError] = useState(null);

  const { client } = useAuthContext();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted, isDirty },
    reset,
  } = useForm({
    defaultValues: {
      mailMomentId: variant?.mailMoment?.id,
      segmentId: variant?.segment,
      name: variant.name,
      description: variant?.description,
      validTerm: variant?.validTerm,
      active: variant.active,
    },
  });

  const onSubmit = (data) => {
    fetcher({
      url: `${config.api.phoenix}/variants/${variant.id}`,
      method: "put",
      customerId: variant.customer.id,
      clientId: client?.id,
      payload: data,
    })
      .then((res) => {
        refetch();
        setEditable(false);
      })
      .catch((err) => {
        setError(err);
      });
  };

  return (
    <Form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      onReset={() => reset({ ...variant })}
    >
      <Row className="g-3">
        <Col md={3} className="w-auto mw-25 ">
          <ImgBox
            imgId={variant.recentProofFileId}
            className="hover-zoom mb-3"
            api={`${config.api.images}/proofs`}
            width={500}
            params={{
              Format: "jpg",
              ResizeMode: "Contain",
              // Width: 350,
              // Height: 350,
            }}
          />
          <Form.Check
            type="switch"
            label="Active"
            {...register("active")}
            readOnly={!editable}
            disabled={!editable}
          />
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <FloatingLabel controlId="name" label="Name*" className="mb-3">
              <Form.Control
                placeholder=" *"
                {...register("name", {
                  required: true,
                })}
                isInvalid={errors?.name}
                isValid={isSubmitted && errors?.name}
                readOnly={!editable}
                disabled={!editable}
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group className="mb-3">
            <FloatingLabel label="Description" controlId="description">
              <Form.Control
                placeholder="Description"
                as="textarea"
                style={{ height: "354px" }}
                {...register("description")}
                readOnly={!editable}
                disabled={!editable}
              />
              <Form.Text muted>
                Plain-text or HTML (HTML editor to be added)
              </Form.Text>
            </FloatingLabel>
          </Form.Group>

          <Form.Group className="mb-3">
            <FloatingLabel
              controlId="validTerm"
              label="Valid Term"
              className="mb-3"
            >
              <Form.Control
                {...register("validTerm", {})}
                isInvalid={errors?.name}
                isValid={isSubmitted && errors?.name}
                readOnly={!editable}
                disabled={!editable}
              />
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col>
          <ListGroup variant="flush">
            <LabeledItem
              {...variant?.program}
              editable={editable}
              label="Program"
              to={`/programs/${variant?.program?.type}/${variant.program?.id}`}
              className="mb-3"
            />
            <LabeledItem
              {...variant?.customer}
              editable={editable}
              label="Customer"
              to={`/customers/${variant.customer?.id}`}
              className="mb-3"
            />

            <LabeledItem
              {...variant?.mailMoment}
              editable={editable}
              label="MailMoment"
              to={`/mailmoments/${variant?.mailMoment?.id}`}
              className="mb-3"
            />
            <LabeledItem
              {...variant?.segment}
              editable={editable}
              label="Segment"
              to={`/setments/${variant?.segment?.id}`}
              className="mb-3"
            />
            <LabeledItem
              {...variant?.treatment}
              label="Treatment"
              editable={editable}
              to={`/treatments/${variant?.treatment?.id}`}
              className="mb-3"
            />
            <LabeledItem
              label="Source"
              name={variant?.source}
              id={variant?.sourceId}
              editable={editable}
              to={
                variant?.source === "order"
                  ? `/orders/${variant?.sourceId}`
                  : null
              }
              className="mb-3"
            />
          </ListGroup>
        </Col>
      </Row>
      {editable ? (
        <div className="text-end">
          <Button
            variant="secondary"
            type="button"
            onClick={() => {
              reset({ ...variant });
              setEditable(false);
            }}
          >
            <BsX className="btn-icon me-1" /> Cancel
          </Button>
          <Button
            variant="success"
            type="submit"
            disabled={!isDirty}
            className="ms-2"
          >
            <BsCheck2 className="btn-icon me-1" /> Save changes
          </Button>
        </div>
      ) : (
        <div className="text-end">
          <Button
            type="button"
            variant="success"
            onClick={() => setEditable(true)}
          >
            <BsPencil className="btn-icon me-1" /> Edit
          </Button>
        </div>
      )}

      <ToastContainer
        className="p-3"
        containerPosition="fixed"
        position="bottom-end"
      >
        <Toast
          onClose={() => setError(null)}
          show={!!error}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">Failed to update program</strong>
            <small className="text-muted">
              {error?.code || "Something went wrong"}
            </small>
          </Toast.Header>
          <Toast.Body>
            <div>{error?.message || "An unexpected error occurred"}</div>
            {!!error?.response?.data?.length ? (
              <div className="px-3 py-1 mt-2 bg-danger bg-opacity-25">
                {error.response.data.map((errorLine, i) => (
                  <code key={i} className="d-block my-2">
                    {errorLine}
                  </code>
                ))}
              </div>
            ) : null}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </Form>
  );
};

export default VariantForm;
