import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../../../context/AuthContext";
import { useParams } from "react-router-dom";
import { config } from "../../../../config";
import { Badge, Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import ImgFileIdInput from "../../../ui/ImgFileIdInput";
import createDetailedErrorMessage from "../../../../utils/detailMessageError";
import { useForm } from "react-hook-form";
import uploadFile from "../../../../utils/fetcherUploadFile";
import fetcher from "../../../../utils/fetcher";
import useFetch from "../../../../utils/useFetch";
import ImgBox from "../../../ui/ImgBox";

import {
  BsCheck2,
  BsPlus,
  BsStarFill,
  BsTrash,
  BsUpload,
  BsX,
} from "react-icons/bs";

import WithLoaderAndError from "../../../ui/WithLoaderAndError";
import SearchBox from "../../../ui/SearchBox";

const CustomerMarketingProfileBrandlogos = ({ mp, mpId, refreshMp }) => {
  const { setToasts, client } = useAuthContext();
  const { customerId } = useParams();
  const [error, setError] = useState(null);

  const [selectedBrandLogos, setSelectedBrandLogos] = useState(
    !!mp?.brandLogos
      ? mp?.brandLogos?.filter((item) => !!item?.fileName && !!item?.fileId)
      : []
  );

  const [showModal, setShowModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [selectedImageToRemove, setSelectedImageToRemove] = useState(null);

  const dataUrl = `${config.api.phoenix}/marketingprofiles/${mpId}/brandlogos`;

  const defaultValues = selectedBrandLogos.reduce((acc, logo, index) => {
    acc[`fileId-${index}`] = logo.fileId;
    acc[`fileName-${index}`] = logo.fileName;
    return acc;
  }, {});

  const {
    data: allBrands,
    error: allBrandsError,
    isValidating: allBrandsIsValidating,
  } = useFetch({
    url: `${config.api.phoenix}/clients/brandlogos`,
    clientId: client?.id,
    customerId: client?.customer?.id,
  });

  const { register, handleSubmit, watch, reset } = useForm({
    defaultValues,
  });

  useEffect(() => {
    if (!!mp && !!mp.brandLogos) {
      setSelectedBrandLogos(
        mp.brandLogos.filter((item) => !!item.fileName && !!item.fileId)
      );
    }
  }, [mp]);

  useEffect(() => {
    const newDefaultValues = selectedBrandLogos.reduce((acc, logo, index) => {
      acc[`fileId-${index}`] = logo.fileId;
      acc[`fileName-${index}`] = logo.fileName;
      return acc;
    }, {});
    reset(newDefaultValues);
  }, [selectedBrandLogos, reset]);

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Failed to upload or submit image",
          delay: 50000,
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  const processFileUpload = (payload) => {
    fetcher({
      url: dataUrl,
      method: "PUT",
      payload: payload,
      customerId: customerId,
      clientId: client?.id,
    })
      .then(() => {
        refreshMp();
      })
      .catch((err) => {
        setError(err);
      });
  };

  const onSubmit = (data) => {
    if (data.fileName && data.fileId) {
      // Handle specific logo update
      const payload = [...selectedBrandLogos];
      payload[data.index] = {
        ...payload[data.index],
        fileName: data.fileName,
        fileId: data.fileId,
      };
      processFileUpload(payload); // Filter out null fileName entries
    } else {
      // General form submission handling
      const payload = selectedBrandLogos.map((logo, index) => ({
        fileName: data[`fileName-${index}`] || logo.fileName, // use existing fileName if no new value provided
        fileId: data[`fileId-${index}`] || logo.fileId, // use existing fileId if no new value provided
      }));

      processFileUpload(payload);
      setShowModal(false);
    }
  };

  const handleOnFileUpload = (file, index) => {
    const format = file.name.split(".").pop();
    const allowedFormats = ["png", "jpg", "jpeg", "psd", "eps"];
    const fileName = file.name;
    const fileCategory = "CustomerUpload";
    const url = `${config.api.files}/Upload`;

    if (!allowedFormats.includes(format)) {
      setError(
        "Unsupported file format. Allowed formats are: PNG, JPG, JPEG, PSD, EPS."
      );
      return;
    }

    uploadFile({
      url: url,
      format: format,
      fileName: fileName,
      fileCategory: fileCategory,
      file: file,
      customerId: customerId,
      clientId: client?.id,
      onSuccess: (data) => {
        onSubmit({
          index: index,
          fileName: file.name,
          fileId: data.fileId,
        });
        setShowModal(false);
      },
      onError: (error) => {
        setError(error);
      },
    });
  };

  const AddNewBrandLogo = (event) => {
    const file = event.target.files[0];
    handleOnFileUpload(file, selectedBrandLogos.length);
  };

  const formProps = {
    watch,
    reset,
    register,
  };

  const isSelected = (fileId) =>
    selectedBrandLogos.filter((item) => item.fileId === fileId).length > 0;

  const handleSelectBrand = (newItem) => {
    if (!newItem.fileName) {
      newItem.fileName = "NoFileName";
    }
    if (
      !isSelected(newItem.fileId) &&
      selectedBrandLogos.length < mp.brandLogos.length
    ) {
      setSelectedBrandLogos([...selectedBrandLogos, newItem]);
    } else {
      setSelectedBrandLogos(
        selectedBrandLogos.filter((item) => item.fileId !== newItem.fileId)
      );
    }
  };

  const removeBrandLogo = (newItem) => {
    const newBrandlogos = selectedBrandLogos.filter(
      (item) => item.fileId !== newItem.fileId
    );
    const payload = newBrandlogos.map((logo) => ({
      fileName: logo.fileName,
      fileId: logo.fileId,
    }));
    processFileUpload(payload);
    setShowRemoveModal(false);
  };

  //Search
  const filterBy = (arr, propsArr = ["text"], q) =>
    arr.filter((item) =>
      propsArr
        .map(
          (prop) =>
            !!item[prop] &&
            item[prop].toLowerCase().indexOf(q.toLowerCase()) > -1
        )
        .some((el) => el === true)
    );

  const [filter, setFilter] = useState("");
  const [filteredBrands, setFilteredBrands] = useState(allBrands);
  useEffect(() => {
    setFilteredBrands(
      !!filter?.length ? filterBy(allBrands, ["fileName"], filter) : allBrands
    );
  }, [filter, allBrands]);

  return (
    <Card className="mb-3 bg-dark bg-opacity-10 border-0 customer">
      <Card.Header className="d-flex justify-content-between align-items-start border-0 bg-transparent ps-4 pt-3 pb-0">
        <h2>
          <BsStarFill
            className="h-auto opacity-50 me-2"
            style={{ width: "1em", padding: ".1em" }}
          />
          Brand logos
        </h2>

        <Badge
          pill
          // bg="primary"
          className="font-monospace text-body bg-opacity-25 bg-primary text-opacity-75"
        >
          {selectedBrandLogos.length} <span className="opacity-50">/</span>{" "}
          {mp?.brandLogos.length}
        </Badge>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-3" xs={5}>
            {selectedBrandLogos?.map((brandlogo, index) => (
              <Col
                key={`${brandlogo.fileId}-${index}`}
                style={{ minWidth: "20%", maxWidth: "20%" }}
                // auto
                //  xs={12} sm={6} md={4}
              >
                <div className="img-wrap-marketingprofile">
                  {/*we use the same class for signatures as we */}
                  <div>
                    <Badge
                      className="font-monospace position-absolute top-0 translate-middle-y z-index-1 bg-primary-tint-50 fs-70 floating-label-badge"
                      bg={null}
                    >
                      {watch(`fileName-${index}`)}
                    </Badge>
                    <ImgFileIdInput
                      id={`fileId-${index}`}
                      label={`Brandlogo ${index}`}
                      fileName={`fileName-${index}`}
                      formProps={formProps}
                      imageContent={selectedBrandLogos[index]}
                      defaultValues={defaultValues}
                      hasFloatingInput={false}
                      resizeMode="contain"
                      imgBg="rgba(255,255,255,.7)"
                      padding={6}
                      onClick={() => setShowModal(true)}
                    />
                  </div>
                  <div className="remove-button">
                    <Button
                      size="sm"
                      variant="danger"
                      onClick={() => {
                        setSelectedImageToRemove(brandlogo);
                        setShowRemoveModal(true);
                      }}
                      className="rounded-circle"
                    >
                      <BsTrash color="white" />
                    </Button>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Form>
      </Card.Body>
      <Card.Footer className="border-0 px-3 pb-3 pt-0 bg-transparent">
        <div className="text-end">
          <Button
            variant="success"
            className="rounded-circle"
            onClick={() => setShowModal(true)}
          >
            <BsPlus />
          </Button>
        </div>
      </Card.Footer>

      <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
        <WithLoaderAndError
          isLoading={allBrandsIsValidating}
          error={allBrandsError}
        >
          <Modal.Header closeButton>
            <h5>
              {"Add brands"}{" "}
              <Badge
                pill
                className="font-monospace text-body bg-opacity-25 bg-primary text-opacity-75"
              >
                {selectedBrandLogos.length}{" "}
                <span className="opacity-50">/</span> {mp?.brandLogos.length}
              </Badge>
            </h5>
          </Modal.Header>
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ paddingLeft: "16px", paddingBottom: "10px" }}
          >
            <h4 style={{ fontSize: "17px" }}>{"Select a brand logo"}</h4>
            <div className="d-flex" style={{ marginRight: "50px" }}>
              <div
                style={{
                  marginRight: "25px",
                  maxWidth: "70%",
                  border: "2px solid grey",
                  borderRadius: "5px",
                }}
              >
                {" "}
                <SearchBox
                  query={filter}
                  setQuery={setFilter}
                  placeholder="Search brands"
                />
              </div>
              <Button
                onClick={() => setSelectedBrandLogos([])}
                variant="secondary"
              >
                {" "}
                Deselect All
              </Button>
            </div>
          </div>
          <Modal.Body style={{ overflowY: "auto", maxHeight: "500px" }}>
            <Row className="g-3 d-flex justify-content-center">
              {filteredBrands?.map((item, index) => (
                <Col
                  sm={3}
                  key={`${index}-${item.fileId}`}
                  style={{ width: "175px" }}
                >
                  <div
                    onClick={() => handleSelectBrand(item)}
                    style={{
                      cursor: "pointer",
                      border: isSelected(item.fileId)
                        ? "2px solid green"
                        : "none",
                    }}
                  >
                    <ImgBox
                      imgId={item.fileId}
                      width={145}
                      resizeMode="contain"
                      padding={10}
                    />
                  </div>
                  <div
                    style={isSelected(item.fileId) ? { marginTop: "-3px" } : {}}
                  >
                    {item.fileName}
                  </div>
                </Col>
              ))}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-between w-100">
              <h3 style={{ fontSize: "15px" }}>{"Or upload an image:"}</h3>
              <div></div>
            </div>
            <div className="d-flex justify-content-between w-100">
              <div>
                {" "}
                <Button
                  onClick={() =>
                    document.getElementById("new-brandlogo-file-upload").click()
                  }
                  variant="secondary"
                >
                  <BsUpload />
                </Button>
                <input
                  type="file"
                  id="new-brandlogo-file-upload"
                  style={{ display: "none" }}
                  accept=".png,.jpg,.jpeg,.psd,.eps"
                  onChange={AddNewBrandLogo}
                />
              </div>
              <div>
                <Button
                  style={{ marginRight: "5px" }}
                  variant="secondary"
                  type="button"
                  onClick={() => {
                    setShowModal(false);
                    setSelectedBrandLogos(
                      mp?.brandLogos?.filter(
                        (item) => !!item?.fileName && !!item?.fileId
                      )
                    );
                  }}
                >
                  <BsX className="btn-icon me-1" /> Cancel
                </Button>
                <Button variant="success" onClick={onSubmit}>
                  <BsCheck2 className="btn-icon me-1" /> Save changes
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </WithLoaderAndError>
      </Modal>
      <Modal
        style={{ marginTop: "150px" }}
        size="lg"
        show={showRemoveModal}
        onHide={() => {
          setShowRemoveModal(false);
          setSelectedImageToRemove(null); // Reset selected image
        }}
      >
        <Modal.Header>
          <h4>{"Remove brand logo"}</h4>
        </Modal.Header>
        <Modal.Body>
          {selectedImageToRemove
            ? `Are you sure you want to remove ${selectedImageToRemove.fileName}?`
            : "No image selected"}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            type="button"
            onClick={() => {
              setShowRemoveModal(false);
              setSelectedImageToRemove(null); // Reset selected image
            }}
          >
            <BsX className="btn-icon me-1" /> No
          </Button>
          <Button
            variant="success"
            onClick={() => {
              if (selectedImageToRemove) {
                removeBrandLogo(selectedImageToRemove);
              }
            }}
          >
            <BsCheck2 className="btn-icon me-1" /> Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export default CustomerMarketingProfileBrandlogos;
