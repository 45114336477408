import {
  BsFileEarmarkRuled,
  BsGeoAlt,
  BsMailbox,
  BsShop,
  BsTruck,
} from "react-icons/bs";

const AddressIcon = ({ type, ...rest }) => {
  if (type === "shop") {
    return <BsShop {...rest} />;
  } else if (type === "delivery") {
    return <BsTruck {...rest} />;
  } else if (type === "postal") {
    return <BsMailbox {...rest} />;
  } else if (type === "invoice") {
    return <BsFileEarmarkRuled {...rest} />;
  } else {
    return <BsGeoAlt {...rest} />;
  }
};

export default AddressIcon;
