import { Modal, Table } from "react-bootstrap";
import Moment from "react-moment";
import LabeledItem from "../../../ui/LabeledItem";
import { useState } from "react";

const VariantProofHistory = (proofHistory) => {
  const [showModal, setShowModal] = useState(false);
  const [currentWarning, setCurrentWarning] = useState([]);

  const handleOpenModal = (warnings) => {
    setShowModal(true);
    setCurrentWarning(warnings);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentWarning([]);
  };

  return (
    <>
      <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Warnings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentWarning && currentWarning.length > 0 ? (
            <ul>
              {currentWarning?.map((warning) => (
                <li key={warning} className="mb-3">
                  {warning}
                </li>
              ))}
            </ul>
          ) : (
            <p>No warnings available.</p>
          )}
        </Modal.Body>
      </Modal>
      <div style={{ maxHeight: "400px", overflowY: "auto" }}>
        <Table responsive hover striped>
          <thead>
            <tr>
              <th>{"Created"}</th>
              <th>{"Document ID"}</th>
              <th>{"Format"}</th>
              <th>{"URL of result"}</th>
              <th>{"Warnings"}</th>
            </tr>
          </thead>
          <tbody>
            {proofHistory?.proofHistory?.map((item, index) => (
              <tr key={index}>
                <td>
                  <Moment format="DD MMM YYYY HH:mm">{item.created}</Moment>
                </td>
                <td className="text-center">
                  {item?.results?.map((instance, index) => {
                    return (
                      <LabeledItem
                        className="d-flex"
                        key={`id-${index}`}
                        editable={false}
                        name={instance.documentId}
                        link
                        to={`/products/${instance.documentId}`} //!!!incorrect endpoint probably but eventually this will go to document
                      />
                    );
                  })}
                </td>
                <td className="text-center">{item.format}</td>
                <td>
                  {item?.results?.map((instance, idx) => {
                    const url = instance.urls && instance.urls[0];
                    return url ? (
                      <a
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={`${instance.documentId}-${idx}`}
                        style={{
                          color: "#0d6efd", // Bootstrap primary color
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {url}
                      </a>
                    ) : null;
                  })}
                </td>

                <td>
                  {item?.results?.map((instance, j) => {
                    const warning = instance?.warnings
                      ? instance.warnings[0]
                      : null;

                    return (
                      <div key={`${instance.documentId}-${j}`}>
                        {warning ? (
                          warning.length > 40 ? (
                            <span
                              className="custom-modal-button"
                              onClick={() =>
                                handleOpenModal(instance?.warnings)
                              }
                            >
                              {
                                <LabeledItem
                                  className="d-flex"
                                  editable={false}
                                  name={`${warning.substring(0, 40)}...`}
                                  link
                                />
                              }
                            </span>
                          ) : (
                            warning
                          )
                        ) : null}
                      </div>
                    );
                  })}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default VariantProofHistory;
