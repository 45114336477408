import React from "react";
import {
  Badge,
  Button,
  Card,
  Alert,
  ListGroup,
  Row,
  Col,
  Toast,
  ListGroupItem,
} from "react-bootstrap";
import { BsCalendarCheckFill } from "react-icons/bs";
import Layout from "../../layout/Layout";
import PageHeading from "../../ui/PageHeading";
import { useAuthContext } from "../../../context/AuthContext";
import { Link } from "react-router-dom";
import ScrollToAnchor from "../../ScrollToAnchor";
import ExampleButtons from "./ExampleButtons";
import ExampleImgBox from "./ExampleImgBox";

const Examples = ({ title = "UI Elements" }) => {
  const { setToasts } = useAuthContext();
  const colors = [
    "primary",
    "secondary",
    "success",
    "warning",
    "danger",
    "info",
    "light",
    "dark",
    "px-dark",
    "px-hl",
    "purple",
    "teal",
  ];
  const opacities = [25, 50, 75, 100];
  const amounts = [10, 25, 50, 75];

  const addFailToast = () => {
    setToasts((currToasts) => [
      ...currToasts,
      {
        id: Date.now(),
        variant: "danger",
        heading: "Failed to apply action",
        delay: 50000,
        text: "Fail toast is a succes",
      },
    ]);
  };

  const addSuccessToast = () => {
    setToasts((currToasts) => [
      ...currToasts,
      {
        id: Date.now(),
        variant: "success",
        heading: "Success!",
        delay: 2000,
        text: "Action successfully applied",
      },
    ]);
  };

  return (
    <Layout pageTitle={title}>
      <ScrollToAnchor />
      <PageHeading bigHead pageTitle={title}>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fugit cumque
          placeat facere, officiis vel natus laboriosam harum dolorem animi.
          Voluptas, delectus tempora nostrum voluptatibus facere blanditiis
          natus praesentium consequuntur eligendi?
        </p>

        <ListGroup variant="flush">
          <ListGroupItem action as={Link} to="/examples/buttons">
            Buttons
          </ListGroupItem>
          <ListGroupItem action as={Link} to="/examples/imgbox">
            ImgBox
          </ListGroupItem>
        </ListGroup>
      </PageHeading>
      <div className="dashboard-wrap mt-n5">
        <ExampleButtons id="buttons" />
        <ExampleImgBox id="imgBox" title="ImgBox" />

        <Card body className="mt-4" id="badges">
          <h2>Badges</h2>
          <h4>Opacity</h4>
          {colors.map((c) =>
            opacities.map((o) => (
              <Badge
                bg={c}
                className={`bg-opacity-${o} me-1 mb-1 font-monospace`}
                key={`badge${c}${o}1`}
              >
                {c}
              </Badge>
            ))
          )}
          <h4>Lighten & darken</h4>
          {colors.map((c) => (
            <p key={`${c}badge2`} className="mb-0">
              {amounts.reverse().map((a) => (
                <Badge
                  bg={`${c}-tint-${a}`}
                  className={`me-1 mb-1 font-monospace`}
                  key={`badge${c}${a}2`}
                >
                  {`${c}-tint-${a}`}
                </Badge>
              ))}
              {amounts.reverse().map((a) => (
                <Badge
                  bg={`${c}-shade-${a}`}
                  className={`me-1 mb-1 font-monospace`}
                  key={`badge${c}${a}2`}
                >
                  {`${c}-shade-${a}`}
                </Badge>
              ))}
            </p>
          ))}
          {/* {colors.map((c) =>
            amounts.map((a) => (
              <Badge
                bg={`${c}-tint-${a}`}
                className={`me-1 mb-1`}
                key={`badge${c}${a}2`}
              >
                {c}
              </Badge>
            ))
          )}
          {colors.map((c) =>
            amounts.map((a) => (
              <Badge
                bg={`${c}-shade-${a}`}
                className={`me-1 mb-1`}
                key={`badge${c}${a}2`}
              >
                {c}
              </Badge>
            ))
          )} */}
        </Card>
        <Card body className="mt-4">
          <h2>Alerts</h2>
          <Row sm={4} className="g-2">
            {colors.map((c) => (
              <Col key={`alert${c}`}>
                <Alert variant={c} dismissible className="mb-0">
                  <Alert.Heading>{c} alert</Alert.Heading>
                  <p>
                    Aww yeah, you successfully read this important alert
                    message.
                  </p>
                  <hr />
                  <Button variant={c}>Such button</Button>
                </Alert>
              </Col>
            ))}
          </Row>
        </Card>
        <Card body className="mt-4">
          <h2>Icons</h2>
          {colors.map((c) =>
            opacities.map((o) => (
              <BsCalendarCheckFill
                className={`text-${c} text-opacity-${o} mb-1 me-1 fs-4`}
                key={`icon${c}${o}`}
              />
            ))
          )}
        </Card>
        <h2 className="mt-4">Cards</h2>
        <Row sm={4} className="g-2">
          {colors.map((c) =>
            opacities.map((o) => (
              <Col key={`card${c}${o}`}>
                <Card
                  bg={c}
                  className={`bg-opacity-${o}`}
                  text={o > 50 ? "light" : "dark"}
                >
                  <Card.Header>Header</Card.Header>
                  <Card.Body>
                    <Card.Title>Card Title</Card.Title>
                    <Card.Text>
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </Card.Text>
                    <Button variant={c}>Go somewhere</Button>
                  </Card.Body>
                </Card>
              </Col>
            ))
          )}

          <Col>
            <Card>
              <Card.Header>Header</Card.Header>
              <Card.Body>
                <Card.Title>Default</Card.Title>
                <Card.Text>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </Card.Text>
                <Button>Go somewhere</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Card body className="mt-4">
          <h2>ListGroup</h2>
          <ListGroup variant="flush">
            <ListGroup.Item action>No style</ListGroup.Item>
            {colors.map((c) => (
              <ListGroup.Item action variant={c} key={`li${c}`}>
                {c}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card>
        <Card body className="mt-4">
          <h2>Toasts</h2>
          <Row sm={4} className="g-2">
            {colors.map((c) =>
              opacities.map((o) => (
                <Col
                  key={`toast${c}${o}`}
                  className={
                    o > 75
                      ? "color-light"
                      : o > 50
                      ? "text-light"
                      : o > 25
                      ? "text-dark"
                      : "color-dark"
                  }
                >
                  <Toast bg={c} className={`bg-opacity-${o}`}>
                    <Toast.Header closeVariant={o > 50 ? "white" : null}>
                      <span className="me-auto fw-semibold">{c} toast</span>
                      <small>11 mins ago</small>
                    </Toast.Header>
                    <Toast.Body>
                      Hello, world! This is a{" "}
                      <span className="fw-semibold">{c} toast</span> message.
                    </Toast.Body>
                  </Toast>
                </Col>
              ))
            )}
            <Col>
              <Button
                onClick={() => addSuccessToast()}
                variant="success"
                className="w-75 m-auto"
              >
                Success toast
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => addFailToast()}
                variant="danger"
                className="w-75 m-auto"
              >
                Danger toast
              </Button>
            </Col>
          </Row>
        </Card>
      </div>
    </Layout>
  );
};

export default Examples;
