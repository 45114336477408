export const ACTIONLIST = "actionList";
export const QUICKSIGHT = "quickSight";

export const CHART_TYPE = {
  ACTIONLIST: "actionList",
  QUICKSIGHT: "quickSight",
};

export const PROGRAM_TYPE = { CAMPAIGN: "campaign", CYCLE: "cycle" };

export const PROGRAM_STATUS = {
  OPEN: "open",
  NOTOPENED: "notOpened",
  CLOSED: "closed",
  UNKNOWN: "unknown",
};

export const VALIDATIONPATTERNS = {
  phone: /^[0-9 -]+$/i,
  numeric: /^[0-9]*$/i,
  integer: /^[-]?[0-9]*$/i,
  currency: /^[0-9]*[.]?[0-9]{0,4}$/i,
  urlStrict: /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/i,
  url: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i,
  email:
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i,
  guid: /^(?:\{{0,1}(?:[0-9a-fA-F]){8}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){12}\}{0,1})$/i,
};

export const RULES = {
  maxLength200: {
    maxLength: {
      value: 200,
      message: "Max 200 characters",
    },
  },
  maxLength50: {
    maxLength: {
      value: 50,
      message: "Max 50 characters",
    },
  },
  numbersOnly: {
    pattern: {
      value: VALIDATIONPATTERNS.numeric,
      message: "Can only contain numbers",
    },
  },
  integer: {
    pattern: {
      value: VALIDATIONPATTERNS.integer,
      message: "Whole numbers only",
    },
  },
  currency: {
    pattern: {
      value: VALIDATIONPATTERNS.currency,
      message: "Only numbers and decimal point",
    },
  },
  guid: {
    pattern: {
      value: VALIDATIONPATTERNS.guid,
      message: "Not a valid GUID",
    },
  },
  email: {
    pattern: {
      value: VALIDATIONPATTERNS.email,
      message: "Not a valid email",
    },
  },
  urlStrict: {
    pattern: {
      value: VALIDATIONPATTERNS.urlStrict,
      message: "Not a valid URL",
    },
  },
  url: {
    pattern: {
      value: VALIDATIONPATTERNS.url,
      message: "Not a valid URL",
    },
  },
};

export const BOOL_OPTIONS = [true, false];

export const [ACS, DEC] = ["Ascending", "Descending"];
