import React from "react";
import "./scss/style.scss";
import { Outlet } from "react-router-dom";
import routes from "./routes";
import { HelmetProvider } from "react-helmet-async";
import { AuthProvider, useAuthContext } from "./context/AuthContext";
import Login from "./components/pages/Login";
import { DrawerProvider } from "./context/DrawerContext";
import Loader from "./components/ui/Loader";
import { ThemeProvider } from "react-bootstrap";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { LayoutProvider } from "./context/LayoutContext";

const AppWithAuth = () => {
  const { user, isLoading } = useAuthContext();
  return isLoading ? <Loader fullPage /> : user ? <Outlet /> : <Login />;
};

const router = createBrowserRouter([
  {
    element: <AppWithAuth />,
    children: routes,
  },
]);

const App = () => {
  return (
    <HelmetProvider>
      <AuthProvider>
        <DrawerProvider>
          <LayoutProvider>
            <ThemeProvider
              breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs"]}
            >
              <RouterProvider router={router} />
            </ThemeProvider>
          </LayoutProvider>
        </DrawerProvider>
      </AuthProvider>
    </HelmetProvider>
  );
};

export default App;
