import parseLinkHeader from "./parseLinkHeader";

const useTotals = ({ linkStr, page = 1, pageSize = 30, itemsCount = 0 }) => {
  const links = parseLinkHeader(linkStr);
  const lastPage = links?.last?.page || page;
  const isLast = !links?.last;
  const lastPageCount = isLast ? itemsCount : 0;
  const total = (lastPage - 1) * pageSize + lastPageCount;
  return {
    total: total,
    isExact: isLast,
  };
};

export default useTotals;
