import React from "react";
import PageSection from "./PageSection";
import camelCaseToText from "../../../utils/camelCaseToText";

const ExampleWrap = ({ component: Component, id, title, ...rest }) => {
  const colors = [
    "primary",
    "secondary",
    "success",
    "warning",
    "danger",
    "info",
    "light",
    "dark",
    "px-dark",
    "px-hl",
    "purple",
    "teal",
  ];
  const modules = [
    "dashboard",
    "customer",
    "order",
    "variant",
    "campaign",
    "cycle",
    "quickSight",
    "actionList",
  ];
  const opacities = [25, 50, 75, 100];
  const amounts = [10, 25, 50, 75];

  return (
    <PageSection id={id}>
      <div className="mt-4">
        {title ? <h2>{title}</h2> : id ? <h2>{camelCaseToText(id)}</h2> : null}
        <Component
          colors={colors}
          modules={modules}
          opacities={opacities}
          amounts={amounts}
          {...rest}
        />
      </div>
    </PageSection>
  );
};

export default ExampleWrap;
