import React from "react";

const PageSection = ({ id, children }) => {
  return (
    <div id={id} style={{ scrollMarginTop: "var(--header-height)" }}>
      {children}
    </div>
  );
};

export default PageSection;
